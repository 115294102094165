import React from 'react';

export const LoadingLogo = (): JSX.Element => {
  return (
    <p
      className="m-auto"
      style={{ fontSize: 100, fontWeight: 200, color: '#d8d8d8' }}
    >
      Siimpl
    </p>
  );
};

import React, { useState, useEffect, Key } from 'react';
import { getToken } from '../Auth/firebase';
import moment from 'moment';
import loadingGif from '../Assets/GIF1v7.gif';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';

interface TabItemType {
  tab: string;
  url: string;
  favIconUrl: string;
  title: string;
}
interface TabCollectionType {
  TabCollectionId: string;
  TabCollectionName: string;
  DateCreated: string;
  TabJSON: string;
}

import './TabCollections.scss';
import Modal from '../Components/Modal/Modal.component';
import { fetchAPI } from '../Helpers/fetchAPI.helper';

export default function TabCollections() {
  const [isLoading, setIsLoading] = useState(true);
  const [tabCollections, setTabCollections] = useState<TabCollectionType[]>([]);
  const [selectedTabCollection, setSelectedTabCollection] =
    useState<TabCollectionType | null>(null);
  const [tabCollectionName, setTabCollectionName] = useState('');

  useEffect(() => {
    getTabCollections();
  }, []);

  const getTabCollections = async () => {
    const accessToken = await getToken();

    fetch(`/apiv2/getTabCollections`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('error');
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setTabCollections(
          data.sort(
            (
              a: { DateCreated: string | number | Date },
              b: { DateCreated: string | number | Date },
            ) =>
              new Date(b.DateCreated).valueOf() -
              new Date(a.DateCreated).valueOf(),
          ),
        );
        setIsLoading(false);
      });
  };

  const moveToTrash = async (TabCollectionId: string) => {
    const accessToken = await getToken();

    fetch('/apiv2/removeTabCollection', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        collectionId: TabCollectionId,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('error');
        }
        return response.json();
      })
      .then(() => {
        setTabCollections((prevTabCollections) =>
          prevTabCollections.filter(
            (el: { TabCollectionId: string }) =>
              el.TabCollectionId !== TabCollectionId,
          ),
        );
      })
      .catch((err) => console.log(err));
  };

  const updateTabCollection = async () => {
    const updatedTab = {
      ...selectedTabCollection,
      TabCollectionName: tabCollectionName,
    };

    fetchAPI
      .post('/apiv2/updateTabCollection', {
        body: updatedTab,
      })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setTabCollections((prevState) =>
          prevState.map((tabCollection) =>
            tabCollection.TabCollectionId ===
            selectedTabCollection?.TabCollectionId
              ? updatedTab
              : tabCollection,
          ),
        );
        setSelectedTabCollection(null);
      })
      .catch((err) => console.log(err));
  };

  if (isLoading) {
    return (
      <div className="d-flex loading-bookmarks" style={{ height: '75vh' }}>
        <img src={loadingGif} />
      </div>
    );
  }

  return (
    <div id="tab-collections">
      <h2 className="styled-header d-flex">Tab Collections</h2>

      <div className="siimpl-grid">
        {tabCollections.map((tabCollection, index) => (
          <div key={index}>
            <div
              className="siimpl-item-wrapper"
              style={{ border: 'solid 1px #eaeaea' }}
            >
              <h6>{tabCollection.TabCollectionName}</h6>
              <div
                className="tabs"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '20px',
                  marginBottom: '35px',
                }}
              >
                {JSON.parse(tabCollection?.TabJSON).map(
                  (tab: TabItemType, index: Key) => (
                    <a
                      href={tab.url}
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                      style={{ marginBottom: 10 }}
                    >
                      <img className="fav-icon" src={tab.favIconUrl} />
                      {tab.title}
                    </a>
                  ),
                )}
              </div>
              <div className="details">
                <span className="date">
                  <small>
                    {moment(tabCollection.DateCreated).format('ll')}
                  </small>
                </span>
                <Dropdown as={ButtonGroup} alignRight>
                  <Dropdown.Toggle variant="link" className="text-muted">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="19" cy="12" r="1"></circle>
                      <circle cx="5" cy="12" r="1"></circle>
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => setSelectedTabCollection(tabCollection)}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="5"
                      onClick={() => moveToTrash(tabCollection.TabCollectionId)}
                    >
                      Remove
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        show={!!selectedTabCollection}
        onClose={() => setSelectedTabCollection(null)}
        maxWidth={460}
      >
        <div
          id="edit-tab-collection"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <h4 className="h4 mr-auto mb-3">Edit Tab Collection</h4>
          <label>Tab Collection Name</label>
          <input
            defaultValue={selectedTabCollection?.TabCollectionName}
            type="text"
            placeholder="Tab Collection Name"
            value={
              tabCollectionName || selectedTabCollection?.TabCollectionName
            }
            onChange={(e) => setTabCollectionName(e.target.value)}
            spellCheck={false}
            autoComplete="off"
          />
          <Button
            style={{ alignSelf: 'flex-end' }}
            onClick={updateTabCollection}
          >
            Update
          </Button>
        </div>
      </Modal>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import BookmarkMasonry from '../Elements/BookmarkMasonry';

import loadingGif from '../Assets/GIF1v7.gif';
import { LoadingLogo } from '../Components/LoadingLogo/LoadingLogo.component';
import { fetchAPI } from '../Helpers/fetchAPI.helper';
import { UseParamsProps } from '../Types/Global';

const Preview = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);

  const [collectionBookmarks, setCollectionBookmarks] = useState([]);
  const [images, setImages] = useState<any[]>([]);

  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const chosenCollection = useRef('');
  const { id } = useParams<UseParamsProps>();

  useEffect(() => {
    getBookmarks();
  }, [id]);

  const getBookmarks = async () => {
    try {
      fetchAPI
        .get(`/apiv2/getCollectionPreview`, {
          headers: {
            collectionid: id,
          },
        })
        .then((data) => {
          const hasAccess = data[0].hasAccess;
          if (!hasAccess) {
            setNoAccess(true);
            return;
          }

          if (data.length > 0) {
            chosenCollection.current = data[0].CollectionName;
          } else {
            chosenCollection.current = 'Collection seems to be empty';
            return;
          }
          const bookmarkSort = data.sort(function (
            a: { DateCreated: string | number | Date },
            b: { DateCreated: string | number | Date },
          ) {
            return (
              new Date(b.DateCreated).valueOf() -
              new Date(a.DateCreated).valueOf()
            );
          });

          const images = [];

          for (let i = 0; i < bookmarkSort.length; i++) {
            if (bookmarkSort[i].BookmarkType === 'image') {
              images.push({
                Id: bookmarkSort[i].Id,
                src: bookmarkSort[i].BookmarkImage,
              });
            }
          }

          setImages(images);
          setCollectionBookmarks(bookmarkSort);
        })
        .catch(console.error)
        .finally(() => {
          console.log('loading finished');
          setIsLoading(false);
        });
    } catch (err) {
      console.error(err);
      console.log('loading finished 2');
      setIsLoading(false);
    }
  };

  const openImageLightbox = (Id: string) => {
    const image = images.find((image) => image.Id === Id);
    setPhotoIndex(image);
    setIsOpen(true);
  };

  if (isLoading) {
    return (
      <div className="d-flex loading-bookmarks" style={{ height: '100vh' }}>
        <div className="text-center m-auto">
          <LoadingLogo />
        </div>
      </div>
    );
  }

  if (noAccess) {
    return (
      <div
        className="d-flex flex-column loading-bookmarks"
        style={{ height: '75vh' }}
      >
        <div className="text-center m-auto">
          <img alt="siimpl loading icon mb-0" src={loadingGif} />
          <h3 className="mx-auto mt-0 w-75" style={{ fontWeight: '200' }}>
            Page either doesn&apos;t exist or you dont have access.
          </h3>
        </div>
      </div>
    );
  }

  return (
    <>
      <BookmarkMasonry
        openImageLightbox={openImageLightbox}
        collectionBookmarks={collectionBookmarks}
        addToSelected={(Id: string) => console.log(Id)}
        moveToTrash={(Id: string) => console.log(parseInt(Id))}
        activeCollectionDetails={null}
        selectedBookmarks={[]}
      />

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default Preview;

import React, { useState, useEffect } from 'react';
import { auth, getUserDocument } from '../Auth/firebase';
import { Nav, Navbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import bg from './img/bg_img.png';
import mockup from './img/siimpl_mockup.png';
// import mockupGif from './img/Siimpl.gif';
import AppleStoreLogo from './img/appstore.png';
import GooglePlayLogo from './img/playstore.png';
import SiimplExtensionPoster from './img/Siimpl Extension - poster.png';
import SiimplAppPoster from './img/Siimpl App - poster.png';

// import Siimpl from './img/Siimpl.mp4';
// import siimplExtensionHowTo from './img/Siimpl Extension - how to.mp4';
// import siimplAppHowTo from './img/Siimpl App - how to.mp4';

import './css/style.default.scss';
import './site.scss';

const Home = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const user = await getUserDocument(userAuth.uid);
        setUser(user);
      }
    });

    if (window.location.hash) {
      const anchor = document.querySelector(window.location.hash);
      anchor.scrollIntoView();
    }
  }, []);

  return (
    <div id="main-site">
      <header className="site-header">
        <Navbar expand="lg" className="position-fixed w-100">
          <div className="container">
            <Navbar.Brand
              href="/"
              className="font-weight-bold"
              style={{ color: '#000000' }}
            >
              Siimpl<span style={{ color: '#43a0d1' }}>.</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto ml-auto">
                <Nav.Link href="#download">How it works</Nav.Link>
                <Nav.Link href="#specs">Learn more</Nav.Link>
                <Nav.Link href="#download">Download</Nav.Link>
                <Nav.Link href="#contact">Contact</Nav.Link>
              </Nav>
              <Nav>
                {!user && (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/login"
                        className="nav-link font-weight-bold mr-3"
                        style={{ paddingTop: '15px' }}
                      >
                        Login
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/login" className="navbar-btn btn btn-primary">
                        Get Started
                      </Link>
                    </li>
                  </>
                )}

                {user && (
                  <li className="nav-item">
                    <Link
                      to="/login"
                      className="nav-link font-weight-bold mr-3"
                      style={{ paddingTop: '15px' }}
                    >
                      Open App
                    </Link>
                  </li>
                )}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>

      <div className="page-holder" style={{ paddingTop: '100px' }}>
        <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>

        <section style={{ minHeight: '60vh', zIndex: 1 }}>
          <div className="container d-flex">
            <div className="row align-items-center hero">
              <div className="col-md-8 m-auto">
                <h1 className="hero-heading text-center">
                  A beautifully, siimpl way to organize
                </h1>
                <p className="lead mt-3 font-weight-light text-center">
                  Save websites, images, and text you find online, then access
                  from anywhere.
                </p>
                <div className="available text-center mt-5 mb-5">
                  <ul className="platforms-list list-inline mb-3">
                    <li className="list-inline-item">
                      <a
                        href="https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/240px-Google_Chrome_icon_%28September_2014%29.svg.png"
                          alt=""
                          className="platform-image img-fluid"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="https://cdn.vox-cdn.com/thumbor/VeEPEECslTmxDJwzV2RHXhslYpg=/0x0:1980x1320/920x613/filters:focal(832x502:1148x818):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/65613211/microsoftedgenewlogo.5.jpg"
                          alt=""
                          className="platform-image img-fluid"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://addons.mozilla.org/en-CA/firefox/addon/siimpl-bookmarking/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Firefox_Project_Logo%2C_2019.svg/1024px-Firefox_Project_Logo%2C_2019.svg.png"
                          alt=""
                          className="platform-image img-fluid"
                        />
                      </a>
                    </li>
                  </ul>
                  <a
                    href="https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={AppleStoreLogo} alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.siimpl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={GooglePlayLogo} alt="" className="ml-2" />
                  </a>
                  <div className="mt-3">
                    <a
                      href="https://www.producthunt.com/posts/siimpl-bookmarking?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-siimpl-bookmarking"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=298742&theme=light"
                        alt="Siimpl Bookmarking - Simple way to organize your bookmarks on any device | Product Hunt"
                        style={{ width: 250, height: 54 }}
                        width="250"
                        height="54"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mockup">
          <div className="container d-flex">
            <div className="row align-items-center w-100">
              <img
                src={mockup}
                alt="website-mockup"
                className="m-auto"
                style={{ width: '50vw' }}
              />
            </div>
          </div>
        </section>

        <section className="how_to">
          <video className="how_to_video" poster={SiimplAppPoster}>
            <source
              src="https://res.cloudinary.com/siimpl/video/upload/v1618928754/Siimpl_App_-_how_to.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
          <video className="how_to_video" poster={SiimplExtensionPoster}>
            <source
              src="https://res.cloudinary.com/siimpl/video/upload/v1618928753/Siimpl_Extension_-_how_to.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        </section>

        <section
          id="specs"
          className="specs mt-5"
          style={{ minHeight: '70vh' }}
        >
          <div className="container d-flex m-auto">
            <div className="row justify-content-md-center">
              <div className="col-lg-12 text-center mb-3">
                <h2>The Siimplist way to save for later</h2>
              </div>
              <div className="col-lg-1 text-center"></div>
              <div className="col-lg-3 text-center p-3">
                <svg
                  viewBox="0 0 24 24"
                  width="100"
                  height="100"
                  stroke="#85daef"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
                <h5>Super Simple</h5>
                <p>Add Bookmarks & Images with a click of a button.</p>
              </div>
              <div className="col-lg-3 text-center p-3">
                <svg
                  viewBox="0 0 24 24"
                  width="100"
                  height="100"
                  stroke="#85daef"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
                <h5>Collect</h5>
                <p>
                  Simply use our extension, web app or app to save it for later.
                </p>
              </div>
              <div className="col-lg-3 text-center p-3">
                <svg
                  viewBox="0 0 24 24"
                  width="100"
                  height="100"
                  stroke="#85daef"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                  <polyline points="16 6 12 2 8 6"></polyline>
                  <line x1="12" y1="2" x2="12" y2="15"></line>
                </svg>
                <h5>Share</h5>
                <p>
                  Share a collection with anyone — allow edits or read only
                  access.
                </p>
              </div>
              <div className="col-lg-1 text-center"></div>

              <div className="col-lg-3 text-center p-3">
                <svg
                  viewBox="0 0 24 24"
                  width="100"
                  height="100"
                  stroke="#85daef"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                  <line x1="1" y1="1" x2="23" y2="23"></line>
                </svg>
                <h5>Hidden</h5>
                <p>
                  Save personal websites and keep them hidden with a pin to
                  access.
                </p>
              </div>
              <div className="col-lg-3 text-center p-3">
                <svg
                  viewBox="0 0 24 24"
                  width="100"
                  height="100"
                  stroke="#85daef"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    ry="2"
                  ></rect>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>
                <h5>Private</h5>
                <p>No one can see your collections unless you share.</p>
              </div>
              <div className="col-lg-3 text-center p-3">
                <svg
                  viewBox="0 0 24 24"
                  width="100"
                  height="100"
                  stroke="#85daef"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon>
                </svg>
                <h5>Anywhere</h5>
                <p>
                  You can access your content from anywhere, phone, tablet or
                  laptop.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="download">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 section-padding pt-0 m-auto text-center">
                <div className="section-header pr-3">
                  <h2 id="download" className="my-5">
                    Extensions
                  </h2>
                  <div className="row justify-content-md-center">
                    <div className="col-lg-3">
                      <div className="circle">
                        <span className="m-auto">1</span>
                      </div>
                      <h5>Install</h5>
                      <p>Install one of the below extensions and login.</p>
                    </div>
                    <div className="col-lg-3">
                      <div className="circle">
                        <span className="m-auto">2</span>
                      </div>
                      <h5>Save</h5>
                      <p>
                        Right click any website, image or text selection and
                        select Save to Siimpl.
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <div className="circle">
                        <span className="m-auto">3</span>
                      </div>
                      <h5>Access</h5>
                      <p>
                        Access the saved item on the web app, app or directly in
                        the extension.
                      </p>
                    </div>
                  </div>
                  <div className="pb-med">
                    <ul className="platforms-list list-inline pt-4">
                      <li className="list-inline-item">
                        <a
                          href="https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/240px-Google_Chrome_icon_%28September_2014%29.svg.png"
                            alt=""
                            className="platform-image img-fluid"
                          />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="https://cdn.vox-cdn.com/thumbor/VeEPEECslTmxDJwzV2RHXhslYpg=/0x0:1980x1320/920x613/filters:focal(832x502:1148x818):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/65613211/microsoftedgenewlogo.5.jpg"
                            alt=""
                            style={{ height: '5.3rem' }}
                            className="platform-image img-fluid"
                          />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://addons.mozilla.org/en-CA/firefox/addon/siimpl-bookmarking/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Firefox_Project_Logo%2C_2019.svg/1024px-Firefox_Project_Logo%2C_2019.svg.png"
                            alt=""
                            className="platform-image img-fluid"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>

                  <h2 className="my-5">Apps</h2>
                  <div className="row justify-content-md-center">
                    <div className="col-lg-3">
                      <div className="circle">
                        <span className="m-auto">1</span>
                      </div>
                      <h5>Install</h5>
                      <p>
                        Install the Siimpl App from your app store of choice and
                        login.
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <div className="circle">
                        <span className="m-auto">2</span>
                      </div>
                      <h5>Save</h5>
                      <p>Navigate to a website/image and hit the share icon.</p>
                    </div>
                    <div className="col-lg-3">
                      <div className="circle">
                        <span className="m-auto">3</span>
                      </div>
                      <h5>Share</h5>
                      <p>
                        Add the Siimpl App to the share menu and then click it.
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <div className="circle">
                        <span className="m-auto">4</span>
                      </div>
                      <h5>Access</h5>
                      <p>
                        Access the saved item on the app, web app or directly in
                        the extension.
                      </p>
                    </div>
                  </div>
                  <div>
                    <ul className="platforms-list list-inline">
                      <li className="list-inline-item">
                        <a
                          href="https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={AppleStoreLogo}
                            alt=""
                            className="platform-image img-fluid"
                            style={{ height: '4.5rem' }}
                          />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.siimpl"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={GooglePlayLogo}
                            alt=""
                            className="platform-image img-fluid"
                            style={{ height: '4.5rem' }}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-black"
          id="contact"
          style={{ minHeight: '50vh' }}
        >
          <div className="container  m-auto">
            <div className="row">
              <div className="col-lg-6 section-padding m-auto text-center">
                <div className="section-header pr-3">
                  <span className="section-header-title text-white">
                    Community
                  </span>
                  <h2 className="h1 text-white">Get in touch</h2>
                  <p className="lead text-white mt-4 mb-4">
                    Our goal is to create a product and service that you’re
                    satisfied with and use every day.
                    <br />
                    <small>
                      If there is something that can be improved or you have an
                      idea then let us know
                    </small>
                  </p>
                  <a href="mailto:hello@siimpl.co" className="btn btn-primary">
                    hello@siimpl.co
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="footer bg-black">
        <div className="container text-center">
          <div className="copyrights">
            <ul className="social list-inline-item">
              <li className="list-inline-item">
                <a href="#" target="_blank" className="social-link">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                  </svg>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#" target="_blank" className="social-link">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                  </svg>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#" target="_blank" className="social-link">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                    <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                  </svg>
                </a>
              </li>
            </ul>
            <p className="copyrights-text mb-0">
              Copyright &copy; {new Date().getFullYear()} All rights reserved —
              Siimpl Software
            </p>
            <p className="copyrights-text mb-0">
              <Button
                variant="link"
                onClick={() => window.open('/terms')}
                className="copyrights-link"
              >
                Terms Of Service
              </Button>
              <Button
                variant="link"
                onClick={() => window.open('/privacy')}
                className="copyrights-link"
              >
                Privacy Policy
              </Button>
              <Button
                variant="link"
                onClick={() => window.open('/datadeletion')}
                className="copyrights-link"
              >
                Data Deletion
              </Button>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;

import { updateSiteTheme } from '../Auth/firebase';

export const updateTheme = (theme: string, setUserTheme: (val: string) => void): void => {
  const isDarkTheme = localStorage.getItem('theme') === 'dark-theme' || theme === 'dark-theme';
  const isLightMode = !isDarkTheme;

  // Get CSS variables for background/foreground
  const styles = getComputedStyle(document.body)
  const black = styles.getPropertyValue('--black')
  const white = styles.getPropertyValue('--white')
  const docEl = document.documentElement

  if (isLightMode) {
    console.log("update theme to dark")
    docEl.style.setProperty('--background', black)
    docEl.style.setProperty('--foreground', white)
    document?.querySelector('html')?.classList.add('darkmode')
    localStorage.setItem('theme', 'dark-theme')
    setUserTheme("dark-theme")
    void updateSiteTheme("dark-theme")
  } else {
    console.log("update theme to light")
    docEl.style.setProperty('--background', white)
    docEl.style.setProperty('--foreground', black)
    document?.querySelector('html')?.classList.remove('darkmode')
    localStorage.removeItem('theme')
    setUserTheme("light-theme")
    void updateSiteTheme("")
  }
}


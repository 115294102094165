import React from 'react'
import { Link } from 'react-router-dom'

export const Logo = (): JSX.Element => {
  return (
		<Link to="/">
			<h4 className="logo m-0 mb-0 d-flex" style={{ color: '#6b6b6b' }}>
				Siimpl<span style={{ color: '#43a0d1' }}>.</span>
			</h4>
		</Link>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { getToken } from '../../Auth/firebase';
import { validateEmail } from '../../Helpers/helpers';
import { useParams } from 'react-router';
import { DataContext } from '../../Context/DataProvider';

import { ReactComponent as CopyIcon } from '../../Assets/feather/copy.svg';
import { ReactComponent as UserIcon } from '../../Assets/feather/user.svg';
import { ReactComponent as TrashCan } from '../../Assets/feather/trash.svg';
import { ReactComponent as LockIcon } from '../../Assets/svg/lock.svg';
import { ReactComponent as UnlockIcon } from '../../Assets/svg/unlock.svg';

import './CollectionSharer.component.scss';
import { fetchAPI } from '../../Helpers/fetchAPI.helper';

interface CollectionSharerProps {
  // TODO: add Collection Type
  collection: any;
  onClose: () => void;
}

export const CollectionSharer = (props: CollectionSharerProps): JSX.Element => {
  const { collection, onClose } = props;
  const { userCollections, setUserCollections } = useContext(DataContext);
  const [visibilityType, setVisibilityType] = useState('public');
  const [visibilityDescription, setVisibilityDescription] = useState('');
  const [collectionVisibility, setCollectionVisibility] = useState('public');
  const [shareURL, setShareURL] = useState('');
  const [emailValid, setEmailValid] = useState<boolean | null>(null);
  const [email, setEmail] = useState('');
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [originalManageUsers, setOriginalManageUsers] = useState<any[]>([]);
  const [manageUsers, setManageUsers] = useState<any[]>([]);
  const [hasNested, setHasNested] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [inviteEmails, setInviteEmails] = useState<any[]>([]);

  const { id: collectionId } = useParams<{ id: string }>();

  const currentCollectionVisibility = collection.isListed;
  const currentVisibilty =
    currentCollectionVisibility === 0
      ? 'Unlisted'
      : currentCollectionVisibility === 1
      ? 'Public'
      : 'Private';

  useEffect(() => {
    void getUsersInCollection(collectionId);
    setShareURL(collection.shareURL);
    setVisibility(currentVisibilty);

    setHasNested(
      userCollections.filter(
        (el: any) => el.CollectionParentId === props.collection.CollectionId,
      ).length > 0,
    );
  }, []);

  useEffect(() => {
    console.log(collection);
  }, [collection]);

  const getUsersInCollection = async (collectionId: string): Promise<void> => {
    // setIsLoadingUserInCollection(true)

    const accessToken = await getToken();

    fetch('/apiv2/getUsersInCollection', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        CollectionId: collectionId,
      },
    })
      .then(async (response) => {
        if (response.status !== 200) {
          console.log('error');
        }
        return await response.json();
      })
      .then((data) => {
        setOriginalManageUsers(data);
        setManageUsers(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const setVisibility = (type: string): void => {
    switch (type) {
      case 'Public':
        setVisibilityDescription('Everyone can see this channel');
        setVisibilityType('Public');
        break;

      case 'Unlisted':
        setVisibilityDescription('Anyone with the link');
        setVisibilityType('Unlisted');
        break;

      case 'Private':
        setVisibilityDescription('People on the list');
        setVisibilityType('Private');
        break;

      default:
        setVisibilityDescription('Everyone can see this channel');
        setVisibilityType('Public');
    }

    setCollectionVisibility(type);
  };

  const setVisibilityPrivate = (): void => {
    const collectionData = {
      CollectionId: collection.CollectionId,
    };

    fetchAPI
      .post('/apiv2/setCollectionVisibilityPrivate', {
        body: collectionData,
      })
      .then((data) => {
        console.log(data);

        setUserCollections((prevState: any[]) =>
          prevState.map((item) =>
            item.CollectionId === collectionId
              ? { ...item, isListed: null, shareURL: null }
              : { ...item },
          ),
        );
      })
      .catch(console.error)
      .finally(onClose);
  };

  const setVisibilityPublicUnlisted = (
    visibility: React.SetStateAction<string>,
  ): void => {
    const collectionId = props.collection.CollectionId;
    const collectionVisibilty = visibility === 'Public' ? 1 : 0;

    const collectionData = {
      CollectionId: collectionId,
      isListed: collectionVisibilty,
    };

    fetchAPI
      .post('/apiv2/setCollectionVisibility', {
        body: collectionData,
      })
      .then((data) => {
        const updatedCollection = {
          ...collection,
          shareURL: data,
          isListed: visibility === 'Unlisted' ? 0 : 1,
        };

        setUserCollections((prevState: any[]) =>
          prevState.map((item) =>
            item.CollectionId === collectionId
              ? updatedCollection
              : { ...item },
          ),
        );
      })
      .catch((err) => {
        toast.error('Error updating visibility 😥', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
        console.log(err);
      })
      .finally(onClose);
  };

  const AddEmail = (): void => {
    if (!emailValid) {
      setInvalidEmailError(true);
      return;
    }

    const emailData = {
      Id: Math.random() * (1000 - 0) + 0,
      userEmail: email,
    };

    setInviteEmails([emailData, ...inviteEmails]);
    setManageUsers([emailData, ...manageUsers]);
    setEmail('');
    setEmailValid(null);
  };

  const removeEmailFromCollection = (emailId: any): void => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Are you sure you want to remove this user from the collection?',
        text: 'They will no longer be able to see or edit the collection',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes remove them',
        cancelButtonText: 'I changed my mind!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetchAPI
            .post('/apiv2/removeEmailFromCollection', {
              headers: {
                emailId,
              },
            })
            .then(() => {
              let emailArr = manageUsers;
              emailArr = emailArr.filter((el) => el.Id !== emailId);
              const inviteEmailsArr = inviteEmails.filter(
                (el) => el.Id !== emailId,
              );
              setInviteEmails(inviteEmailsArr);
              setManageUsers(emailArr);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch(console.error);
  };

  const sendInvites = (): void => {
    const inviteEmailOnly = [] as any[];

    inviteEmails.map((el) => inviteEmailOnly.push(el.userEmail));

    const inviteData = {
      CollectionId: props.collection.CollectionId,
      InviteEmails: inviteEmailOnly,
      CollectionName: props.collection.CollectionName,
      CollectionDesc: props.collection.CollectionDesc,
    };

    fetchAPI
      .post('/apiv2/SendInvites', {
        body: inviteData,
      })
      .then(() => {
        setInviteEmails([]);
        toast('Invites Sent!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      })
      .catch((err) => {
        toast.error(`${err} 😥`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      });
  };

  const updateCollectionShare = (): void => {
    if (collectionVisibility === 'Private') {
      console.log(emailsAdded);
      if (emailsAdded) {
        sendInvites();
      }

      setVisibilityPrivate();
      return;
    }
    setVisibilityPublicUnlisted(collectionVisibility);
  };

  const hasChanges = useMemo(
    () =>
      currentVisibilty !== collectionVisibility ||
      originalManageUsers !== manageUsers,
    [currentVisibilty, collectionVisibility, originalManageUsers, manageUsers],
  );

  const copyShareURL = (): void => {
    void navigator?.clipboard?.writeText(shareURL);
  };

  const emailsAdded = originalManageUsers !== manageUsers;

  return (
    <div className="collection-sharer">
      <div className="sharer">
        <div className="collection-header">
          <h4 className="mr-auto">Share Collection</h4>
        </div>
        <div className="collection-note">
          Share this collection with everyone, select people or keep is private
        </div>
        {hasNested && (
          <div className="nested-warning">
            <strong>
              This seems to have nested collections - sharing this will also
              share the nested collections
            </strong>
          </div>
        )}

        <div className="mt-3">
          <div className="collection-subheader">Collection Link</div>
          <div className="collection-sharer-input">
            <input
              value={shareURL}
              placeholder="visible once saved as public/unlisted"
              disabled
            />
            <Button className="copy-button" onClick={() => copyShareURL()}>
              <CopyIcon />
              Copy
            </Button>
          </div>
        </div>
        <div className="sharer-type-container d-flex my-3">
          <div className="sharer-type">
            {visibilityType === 'Public' && <UnlockIcon />}
            {visibilityType === 'Private' && <LockIcon />}
            {visibilityType === 'Unlisted' && <UserIcon />}
          </div>
          <div>
            <Dropdown as={ButtonGroup} alignRight>
              <Dropdown.Toggle variant="link">
                {visibilityDescription}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => setVisibility('Public')}
                >
                  Public
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => setVisibility('Private')}
                >
                  Private
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => setVisibility('Unlisted')}
                >
                  Unlisted
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="sharer-type-description">
              {visibilityDescription}
            </div>
          </div>
        </div>

        {collectionVisibility === 'Private' && (
          <>
            <div>
              <div className="collection-subheader">Invite team members</div>
              <div className="sharer-invite">
                <div className="collection-sharer-input">
                  <input
                    type="text"
                    placeholder="email address"
                    value={email}
                    onChange={(e) => {
                      setEmailValid(validateEmail(e.target.value));
                      setEmail(e.target.value.toLowerCase());
                      setInvalidEmailError(false);
                    }}
                    autoFocus
                    spellCheck={false}
                    autoComplete="off"
                  />
                  <Button className="invite-button" onClick={() => AddEmail()}>
                    Add user
                  </Button>
                </div>
              </div>

              {invalidEmailError && <small>Invalid email address</small>}
            </div>

            {manageUsers.map((item, index) => (
              <div className="email-item" key={index}>
                <div className="email-item-address">
                  <UserIcon />

                  <p style={{ fontSize: '14px', margin: 'auto 15px' }}>
                    {item?.userEmail}
                  </p>
                </div>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={''}>Remove user from Collection!</Tooltip>
                  }
                >
                  <Button
                    variant="link"
                    className="remove-email text-muted float-right"
                    onClick={() => removeEmailFromCollection(item.Id)}
                  >
                    <TrashCan />
                  </Button>
                </OverlayTrigger>
              </div>
            ))}
          </>
        )}
        {!isLoading && (
          <div className="sharer-options mt-4">
            <Button
              onClick={() => updateCollectionShare()}
              disabled={!hasChanges}
            >
              {emailsAdded ? 'Send Invites & Save' : 'Save'}
            </Button>
            {hasChanges && (
              <Button onClick={() => onClose()} variant="outline-secondary">
                Discard Changes
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

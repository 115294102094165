import React from 'react';
import { Button } from 'react-bootstrap';
import { LoadingLogo } from '../Components/LoadingLogo/LoadingLogo.component';

export const PreviewNoAccess = (): JSX.Element => {
  return (
    <div
      className="d-flex flex-column loading-bookmarks"
      style={{ height: '75vh' }}
    >
      <div className="text-center m-auto">
        <LoadingLogo />
        <h3 className="mx-auto mt-0 w-75" style={{ fontWeight: '200' }}>
          You need to login to view this preview
        </h3>
        <Button variant="primary" href="/">
          Login
        </Button>
      </div>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const DataContext = createContext({
  collection: [],
  userCollections: [],
  setCollectionBookmarks: (value) => {},
  collectionBookmarks: [],
  userBookmarks: [],
  setUserBookmarks: (value) => {},
  setUserCollections: (value) => {},
  setSocket: (value) => {},
});

export default function DataProvider(props) {
  const [userCollections, setUserCollections] = useState([]);
  const [userBookmarks, setUserBookmarks] = useState([]);
  const [collectionBookmarks, setCollectionBookmarks] = useState([]);
  const [userTheme, setUserTheme] = useState(null);
  const [userTags, setUserTags] = useState([]);
  const [bookmarkDisplayType, setBookmarkDisplayType] = useState('Grid');
  const [socket, setSocket] = useState(null);

  const getParams = () => {
    return useParams();
  };

  const providerValue = useMemo(
    () => ({
      userCollections,
      setUserCollections,
      userBookmarks,
      setUserBookmarks,
      userTheme,
      setUserTheme,
      userTags,
      setUserTags,
      socket,
      setSocket,
      bookmarkDisplayType,
      setBookmarkDisplayType,
      collectionBookmarks,
      setCollectionBookmarks,
      getParams,
    }),
    [
      userCollections,
      setUserCollections,
      userBookmarks,
      setUserBookmarks,
      userTheme,
      setUserTheme,
      userTags,
      setUserTags,
      socket,
      setSocket,
      bookmarkDisplayType,
      setBookmarkDisplayType,
      collectionBookmarks,
      setCollectionBookmarks,
      getParams,
    ],
  );

  return (
    <DataContext.Provider value={providerValue}>
      {props.children}
    </DataContext.Provider>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useState } from 'react';

interface DragNDropProps {
  children: ReactNode;
  id: string;
  allowDrop: boolean;
  setBookmarkByDrag: (collectionDetails: any) => void;
}

export const DragNDrop = (props: DragNDropProps): JSX.Element => {
  const { children, id, allowDrop, setBookmarkByDrag } = props;
  const [dragOver, setDragOver] = useState('');

  const drop = (ev: any): any => {
    ev.preventDefault();

    const dropItemId =
      ev.dataTransfer.getData('text') &&
      ev.dataTransfer.getData('text').length > 0
        ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `[${ev.dataTransfer.getData('text')}]`
        : ev.dataTransfer.getData('bookmarks');

    if (dropItemId === '[]') {
      return;
    }

    const Collection = ev.target.closest('.sidebar-item');
    const CollectionId = Collection.id;

    if (!CollectionId) {
      return;
    }

    const collectionDetails = {
      moveTo: CollectionId,
      bookmarkIds: JSON.parse(dropItemId),
      dragType: 'move',
    };

    setBookmarkByDrag(collectionDetails);
  };

  return (
    <div
      id={id}
      className={dragOver === id ? 'drag-over--active' : 'drag-over'}
      onDrop={(e) => {
        if (!allowDrop) {
          return;
        }
        drop(e);
        setDragOver(id);
      }}
      onDragOver={(e) => {
        if (!allowDrop) {
          return;
        }
        e.preventDefault();
        setDragOver(id);
      }}
      onDragLeave={() => {
        setDragOver('');
      }}
    >
      {children}
    </div>
  );
};

import React from 'react'
import styled from '@emotion/styled'
import { Form, FormControlProps } from 'react-bootstrap'
import { InputBaseProps } from '../../defintions/baseTypes.defintions'

export const SearchBarContainer = styled.div`
  position: relative;
  padding-left: 32px;
  margin-right: 10px;
`

type Searchbar = FormControlProps &
InputBaseProps & {
  isActive?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchBar = styled((props: Searchbar) => (
  <Form.Control {...props} />
))`
  font-size: 20px !important;
  font-weight: 300;
  width: ${(props) => (props.isActive ? '25vw' : '7.5vw')};
  min-width: 200px;
  height: 40px;
  background: #fbfbfb;
  padding-left: 20px;
  margin: auto !important;
  border: solid 0.5px #ebebeb !important;
  border-radius: 5px;
`

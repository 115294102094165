import React, { useEffect, useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { newlineToHtmlBr, htmlBrToNewline } from '../Helpers/helpers';
import { getToken } from '../Auth/firebase';
import { Button, Spinner } from 'react-bootstrap';
import loadingGif from '../Assets/GIF1v7.gif';

import './SnippetEditor.scss';

export default function Notes(props) {
  const { selectedTextSnippet } = props;
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [selectedNoteBlurb, setSelectedNoteBlurb] = useState('');
  const [selectedNoteTitle, setSelectedNoteTitle] = useState('');

  const editor = useRef(null);

  useEffect(() => {
    setLoading(true);
    setSelectedNoteTitle(selectedTextSnippet?.BookmarkName);
    setSelectedNoteBlurb(selectedTextSnippet?.BookmarkBlurb);
    setLoading(false);
  }, [selectedTextSnippet]);

  const updateNote = async () => {
    setIsSaving(true);
    const accessToken = await getToken();

    const noteData = {
      Id: selectedTextSnippet?.Id,
      BookmarkName: selectedNoteTitle,
      BookmarkBlurb: encodeURIComponent(
        htmlBrToNewline(selectedNoteBlurb || ''),
      ),
    };

    fetch('/apiv2/updateNote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(noteData),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('error');
        }
        return response.json();
      })
      .then(() => {
        props.updateTextSnippet(noteData);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsSaving(false));
  };

  const cleanSnippet = () => {
    try {
      return decodeURIComponent(newlineToHtmlBr(selectedNoteBlurb || ''));
    } catch {
      return selectedNoteBlurb || '';
    }
  };

  const config = useMemo(() => {
    return {
      defaultMode: selectedTextSnippet?.isCodeSnippet ? 2 : 1,
      readonly: false,
      toolbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      disablePlugins:
        'about,add-new-line,backspace,class-span,clean-html,clipboard,copyformat,drag-and-drop,drag-and-drop-element,dtd,enter,error-messages,file,focus,format-block,fullsize,hotkeys,xpath,wrap-nodes,video,table,table-keyboard-navigation,tab,symbols,sticky,stat,spellcheck,speech-recognize,select-cells,select,search,resizer,resize-handler,resize-cells,hr,iframe,image,image-processor,image-properties,justify,key-arrow-outside,limit,media,mobile,paste,paste-from-word,paste-storage,placeholder,powered-by-jodit,preview,ordered-list,print',
      width: 500,
      inline: true,
      toolbarInlineForSelection: true,
      showPlaceholder: false,
      spellChecker: false,
      sourceEditorNativeOptions: {
        theme: 'ace/theme/eclipse',
        showGutter: true,
      },
    };
  }, [selectedTextSnippet?.isCodeSnippet]);

  if (isLoading) {
    return (
      <div className="d-flex loading-bookmarks" style={{ height: '75vh' }}>
        <img src={loadingGif} />
      </div>
    );
  }

  return (
    <div id="edit-snippet">
      <div className="creator_container">
        <div className="creator-input-container">
          <input
            className="invisible-input"
            type="text"
            placeholder="Snippet Name"
            value={selectedNoteTitle}
            onChange={(e) => setSelectedNoteTitle(e.target.value)}
            spellCheck={false}
            autoComplete="off"
          />
          <Button onClick={() => updateNote()} disabled={isSaving}>
            {isSaving ? (
              <>
                Updating{' '}
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  style={{ marginLeft: 5 }}
                />
              </>
            ) : (
              'Update'
            )}
          </Button>
        </div>

        <div className="text-left">
          <JoditEditor
            spellCheck={false}
            ref={editor}
            value={cleanSnippet()}
            config={config}
            onChange={(value) => setSelectedNoteBlurb(value)}
            tabIndex={1} // tabIndex of textarea
          />
        </div>
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Component } from 'react';

export class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <p style={{ fontSize: 100, fontWeight: 200, color: '#d8d8d8' }}>
            Siimpl
          </p>
          <span style={{ fontSize: 30, fontWeight: 200, color: '#474747' }}>
            Something went wrong.
            <br />
            Try refreshing the browser and if the issue persists please contact:
            <br />
            <br />
            <a href="mailto:hello@siimpl.co">hello@siimpl.co</a>
          </span>
        </div>
      );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return this.props.children;
  }
}

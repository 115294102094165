import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../Context/DataProvider';
import { getToken } from '../Auth/firebase';
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

import './CollectionEditor.scss';

export default function CollectionEditor(props) {
  const [collectionId, setCollectionId] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [collectionDesc, setCollectionDesc] = useState('');
  const [collectionParentId, setCollectionParentId] = useState(null);
  const [collectionParentName, setCollectionParentName] = useState('');

  const [isHidden, setIsHidden] = useState(false);

  const [isNested, setIsNested] = useState(false);

  const { userCollections, setUserCollections } = useContext(DataContext);
  const history = useHistory();

  useEffect(() => {
    if (props.collection) {
      let getCollectionParentName;

      if (props.collection.CollectionParentId) {
        getCollectionParentName = userCollections.filter(
          (collection) =>
            collection.CollectionId === props.collection.CollectionParentId,
        );
        setCollectionParentName(getCollectionParentName[0].CollectionName);
      }

      setCollectionId(props.collection.CollectionId);
      setCollectionName(props.collection.CollectionName);
      setCollectionDesc(props.collection.CollectionDesc);
      setIsNested(props.collection.isNested);
      setCollectionParentId(props.collection.CollectionParentId);
      setIsHidden(props.collection.isHidden === 1);
    }
  }, [props.collection]);

  const updateCollection = async () => {
    if (isNested === true && collectionParentId === null) {
      return;
    }

    const accessToken = await getToken();
    const collection = {
      CollectionName: collectionName,
      CollectionDesc: collectionDesc,
      isHidden,
      isNested,
      CollectionParentId: isNested ? collectionParentId : null,
      UpdatedOn: 0,
    };

    fetch('/apiv2/updateCollectionDetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        collectionid: collectionId,
      },
      body: JSON.stringify(collection),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('error');
        }
        return response.json();
      })
      .then(() => {
        setCollectionName('');
        setCollectionDesc('');
        setIsNested(false);
        setIsHidden(false);
        setCollectionParentId(null);

        const updatedCollection = userCollections.filter(
          (el) => el.CollectionId === collectionId,
        )[0];

        updatedCollection.CollectionName = collection.CollectionName;
        updatedCollection.CollectionDesc = collection.CollectionDesc;
        updatedCollection.isHidden = isHidden ? 1 : 0;
        updatedCollection.isNested = isNested || 0;
        updatedCollection.CollectionParentId = isNested
          ? collectionParentId
          : null;

        if (isHidden) {
          setUserCollections((prevState) =>
            prevState.filter((item) => item.CollectionId !== collectionId),
          );
          props.close();
          return;
        }

        setUserCollections((prevState) =>
          prevState.map((item) =>
            item.CollectionId === collectionId
              ? updatedCollection
              : { ...item },
          ),
        );
        props.close();
      });
  };

  const addCollection = async () => {
    if (isNested === true && collectionParentId === null) {
      return;
    }

    const accessToken = await getToken();
    const collection = {
      CollectionName: collectionName,
      CollectionDesc: collectionDesc,
      isHidden,
      isNested,
      CollectionParentId: collectionParentId,
      CreatedOn: 0,
    };
    fetch('/apiv2/addCollection', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(collection),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('error');
        }
        return response.json();
      })
      .then((data) => {
        data.isOwn = 1;
        data.isEditable = 1;
        data.BookmarkCount = 0;
        data.isListed = null;
        data.isNested = isNested || 0;
        data.isHidden = isHidden ? 1 : 0;
        data.CollectionParentId = isNested ? collectionParentId : null;

        setCollectionName('');
        setCollectionDesc('');
        setIsHidden(false);
        setIsNested(false);
        setCollectionParentId(null);
        setUserCollections([data, ...userCollections]);
        props.close();
      });
  };

  const removeCollection = async () => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Are you sure you want to delete this collection?',
        text: "All bookmarks within the collection will also be deleted.. also you won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText:
          'Yes, delete collection and all bookmarks within it!',
        cancelButtonText: 'I changed my mind!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const isParent = userCollections.filter(
            (collection) => collection.CollectionParentId === collectionId,
          );
          if (isParent.length > 0) {
            Swal.fire(
              `Couldn't delete collection`,
              `It look's like you have collections nested within this collection. Please move or delete the nested collections first.`,
              'error',
            );
            return;
          }
          const accessToken = await getToken();

          const collectionDetails = {
            originalId: collectionId,
            moveTo: 'Trash',
          };

          fetch('/apiv2/removeCollectionDetails', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(collectionDetails),
          })
            .then((response) => {
              if (response.status !== 200) {
                console.log('error');
              }
              return response.json();
            })
            .then(() => {
              setUserCollections(
                userCollections.filter(
                  (el) => el.CollectionId !== collectionId,
                ),
              );
              history.push(`/b/collection/home`);
              props.close();
            });
        }
      });
  };

  return (
    <div id="collection-editor">
      <h4 className="h4 mr-auto">{props.isAdd ? 'Add' : 'Edit'} Collection</h4>

      <div style={{ overflow: 'auto', height: '100%' }}>
        <div className="input-wrap">
          <label>Collection Name</label>
          <input
            type="text"
            placeholder="Collection Name"
            value={collectionName}
            onChange={(e) => setCollectionName(e.target.value)}
            spellCheck={false}
            autoComplete="off"
          />
        </div>
        <div className="input-wrap">
          <label>Collection Description</label>
          <input
            type="text"
            value={collectionDesc}
            onChange={(e) => setCollectionDesc(e.target.value)}
            spellCheck={false}
            autoComplete="off"
          />
        </div>
        <div>
          <div className="extra-option mb-3">
            <div className="extra-box">
              <div
                className="collection-title"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Nest Collection
                <div className="switch">
                  <Form.Check
                    type="switch"
                    id="collection-list-switch"
                    label=""
                    checked={isNested}
                    onChange={() => setIsNested(!isNested)}
                  />
                </div>
              </div>
              <div className="collection-note">
                Nest this collection within another.
              </div>
              <div className="collection-note">
                Nested under:{' '}
                <strong>{collectionParentName || 'Not Nested'}</strong>
              </div>
            </div>
          </div>
          {isNested ? (
            <div className="collection-selector-wrapper">
              <div className="collection-selector">
                {userCollections
                  .filter((el) => el.isNested === 0)
                  .map((item, index) => (
                    <div
                      key={index}
                      className={
                        item.CollectionId === collectionParentId
                          ? 'collection-selector-item active'
                          : 'collection-selector-item'
                      }
                      onClick={() => {
                        setCollectionParentId(item.CollectionId);
                        setCollectionParentName(item.CollectionName);
                      }}
                    >
                      <p className="mb-0">{item.CollectionName}</p>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div />
          )}

          <div className="extra-option">
            <div className="extra-box">
              <div
                className="collection-title"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Hide Collection{' '}
                <div className="switch">
                  <Form.Check
                    type="switch"
                    id="collection-hidden-switch"
                    label=""
                    checked={isHidden}
                    onChange={() => setIsHidden(!isHidden)}
                  />
                </div>
              </div>
              <div className="collection-note">
                Hidden collections will require a pin to see
              </div>
              <div className="collection-note">
                Collection is currently:{' '}
                <strong>{isHidden ? 'Hidden' : 'Visible'}</strong>
              </div>
            </div>
          </div>
          {!props.isAdd && (
            <div className="w-100 text-left danger-zone">
              <div className="collection-title">Delete Collection</div>
              <p className="collection-note">
                Make sure to move bookmarks out of this collection if needed!
              </p>
              <Button
                variant="variant"
                className="p-0 m-0 text-danger"
                onClick={() => removeCollection(collectionId)}
              >
                <small>Delete this collection</small>
              </Button>
            </div>
          )}
        </div>
        <hr />
        <div className="creator-footer">
          <Button
            variant="primary"
            className="button-stroke button-small ml-0"
            onClick={() => {
              props.isAdd ? addCollection() : updateCollection();
            }}
            disabled={!collectionName}
          >
            {props.isAdd ? 'Add' : 'Update'} Collection
          </Button>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider.provider';
import DataProvider, { DataContext } from '../Context/DataProvider';
import socketIOClient from 'socket.io-client';

import { ReactComponent as MobileMenu } from '../Assets/svg/mobileMenu.svg';

import { Button } from 'react-bootstrap';
import Bookmarks from './Bookmarks';
import TabCollections from './TabCollections';
import TextSnippets from './TextSnippets';
import Preview from './Preview.view';
import Public from './Public';

import SearchContainer from '../Elements/SearchContainer';

import './Application.scss';
import '../Dark-Theme.scss';
import { LoadingLogo } from '../Components/LoadingLogo/LoadingLogo.component';
import { Logo } from '../Components/Logo/Logo.component';
import {
  SideNavigation,
  UserCollectionProps,
} from '../Components/SideNavigation/SideNavigation.component';
import { Overlay } from '../Components/SideNavigation/Components/Overlay.component';
import { fetchAPI, getToken } from '../Helpers/fetchAPI.helper';
import { Searchbar } from '../Components/Searchbar/Searchbar.component';
import { SiimplAdd } from '../Components/SiimplAdd/SiimplAdd.component';

function Application(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [bookmarkByDrag, setBookmarkByDrag] =
    useState<UserCollectionProps | null>(null);

  const { userTheme, user } = useContext(UserContext);
  const { setUserCollections, setSocket } = useContext(DataContext);

  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const connectSocket = async (): Promise<any> => {
    const accessToken = await getToken();

    const socket = socketIOClient(process.env.REACT_APP_WSBASE || '', {
      transports: ['websocket'],
      upgrade: false,
      auth: {
        token: `Bearer ${accessToken}`,
      },
    });

    socket.emit('join', user.uid);

    setSocket(socket);

    return () => {
      socket.disconnect();
      setSocket(null);
    };
  };

  useEffect(() => {
    setSearchResults([]);
  }, [history.location]);

  useEffect(() => {
    getCollections();
  }, []);

  useEffect(() => {
    async function socketConnector() {
      await connectSocket();
    }

    socketConnector();
  }, []);

  const getCollections = (): void => {
    fetchAPI
      .get('/apiv2/getCollections')
      .then((data) => {
        setUserCollections(data);
        setTimeout(function () {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err);
      });
  };

  const openSidebar = (open: boolean): void => {
    setSidebarActive(open);
    const body = document.querySelector('body');

    if (body == null) {
      return;
    }

    if (open) {
      body.style.overflow = 'hidden';
      return;
    }
    body.style.overflow = 'auto';
  };

  if (isLoading) {
    return (
      <div className="d-flex" style={{ height: '100vh' }}>
        <LoadingLogo />
      </div>
    );
  }

  return (
    <div className="siimpl" id="siimpl-application" data-type={userTheme}>
      <header>
        <div className="section-left">
          <Button
            variant="link"
            className="mob-menu-btn d-none"
            onClick={() => openSidebar(true)}
          >
            <MobileMenu />
          </Button>
          {!sidebarActive && <Logo />}
        </div>

        <div className="section-right">
          <Searchbar setSearchResults={setSearchResults} />
          <SiimplAdd />
        </div>
      </header>
      <div className="siimpl-container">
        <div className="siimpl-wrapper">
          <div className="siimpl-content">
            <Overlay
              isActive={sidebarActive}
              handleClick={() => openSidebar(false)}
            />
            <SideNavigation
              sidebarActive={sidebarActive}
              setSidebarActive={setSidebarActive}
              setBookmarkByDrag={(collectionDetails: UserCollectionProps) =>
                setBookmarkByDrag(collectionDetails)
              }
            />
            <div className="siimpl-container-right">
              {searchResults.length > 0 ? (
                <SearchContainer
                  searchResults={searchResults}
                  setActiveCollection={(collectionId: string) => {
                    collectionId === 'Selection Text'
                      ? history.push('/b/text-snippets')
                      : history.push(`/b/collection/${collectionId}`);
                  }}
                />
              ) : (
                <>
                  <Route
                    path="/b/collection/:id"
                    render={() => (
                      <Bookmarks
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        bookmarkByDrag={bookmarkByDrag}
                        resetBookmarkByDrag={() => setBookmarkByDrag(null)}
                      />
                    )}
                  />
                  <Route path="/b/tabs" render={() => <TabCollections />} />
                  <Route
                    path="/b/text-snippets"
                    render={() => <TextSnippets />}
                  />
                  <Route path="/b/preview/:id" render={() => <Preview />} />
                  <Route path="/b/public/:id" render={() => <Public />} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ApplicationWrapper = () => {
  return (
    <DataProvider>
      <Application />
    </DataProvider>
  );
};

export default ApplicationWrapper;

import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { UserContext } from './providers/UserProvider.provider';

import Private from './WebSite/Private';
import Terms from './WebSite/Terms';
import DataDeletion from './WebSite/DataDeletion';
import Privacy from './WebSite/Privacy';
import Public from './WebSite/Public';
import Home from './WebSite/Home';
import Login from './Auth/Login';
import { PreviewNoAccess } from './Application/PreviewNoAccess.view';
import ExtensionLogin from './Auth/ExtensionLogin';
import ApplicationWrapper from './Application/Application.view';
import Help from './WebSite/help';

function ApplicationContainer(): JSX.Element {
  const { user } = useContext(UserContext);

  return (
    <Router>
      {user && (
        <Switch>
          <Route path="/b/" render={() => <ApplicationWrapper />} />
        </Switch>
      )}

      <Switch>
        <Route path="/" exact render={() => <Home />} />
        <Route
          path="/extension-login"
          exact
          render={() => <ExtensionLogin />}
        />
        <Route path="/login" exact render={() => <Login />} />
        <Route path="/b/preview/:id" exact render={() => <PreviewNoAccess />} />
        <Route path="/b/public/:id" exact render={() => <Public />} />

        <Route path="/private" exact render={() => <Private />} />
        <Route path="/Terms" exact render={() => <Terms />} />
        <Route path="/Privacy" exact render={() => <Privacy />} />
        <Route path="/DataDeletion" exact render={() => <DataDeletion />} />
        <Route path="/home" exact render={() => <Home />} />
        <Route path="/help" exact render={() => <Help />} />
        {!user && <Route path="*" render={() => <Home />} />}
      </Switch>
    </Router>
  );
}

export default ApplicationContainer;

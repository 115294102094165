import React, { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  auth,
  getUserDocument,
  generateUserDocument,
  updateProfilePic,
  getToken,
} from '../../Auth/firebase';
import { UserContext } from '../../providers/UserProvider.provider';
import { DataContext } from '../../Context/DataProvider';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import pckg from '../../../package.json';

import './Settings.scss';

export default function Settings() {
  const [usernameChange, setUsernameChange] = useState(false);
  const [siimplSauceChange, setSiimplSauceChange] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [displayName, setDisplayName] = useState(null);
  const [siimplSaucePin, setSiimplSauce] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [, setActiveDisplayType] = useState(null);
  const [importing, setImporting] = useState(false);
  const [isDangerZone, setIsDangerZone] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { userDetails, setUserDetails } = useContext(UserContext);
  const { setUserBookmarks, setUserCollections } = useContext(DataContext);

  const inputEl = useRef(null);
  const fileInput = React.useRef(null);

  const history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const user = await getUserDocument(userAuth.uid);

        setUserDetails(user);
        setPhotoURL(user.photoURL);
        setDisplayName(user.displayName);
        setActiveDisplayType(user.displayType);
      }
    });
  }, []);

  const onfileUpload = (e) => {
    let imageBase64;
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      const img = document.createElement('img');
      img.src = URL.createObjectURL(file); // stores image src

      img.onload = async function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);
        imageBase64 = canvas.toDataURL('image/jpeg', 0.1);

        setUserDetails((prevState) => ({
          ...prevState,
          photoURL: imageBase64,
        }));
        setPhotoURL(imageBase64);
        const photoURL = imageBase64;
        await updateProfilePic(userDetails, photoURL);
      };
    };
    reader.readAsDataURL(file);
  };

  const updateUsername = async () => {
    if (!usernameChange) {
      return;
    }

    const userBio = '';
    const photoURL =
      userDetails.photoURL !== undefined
        ? userDetails.photoURL
        : 'https://siimpl.co/favicon.ico';

    await generateUserDocument(userDetails, { displayName, photoURL, userBio });
    toast.info(`😃 User Info updated!`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateSiimplSauce = async () => {
    if (!siimplSauceChange) {
      return;
    }

    try {
      const siimplSauce = CryptoJS.AES.encrypt(
        siimplSaucePin,
        process.env.REACT_APP_SAUCEKEY,
      ).toString(); // digest;

      const userBio = '';
      const photoURL = userDetails.photoURL ?? null;

      const updateUser = await generateUserDocument(userDetails, {
        displayName,
        photoURL,
        userBio,
        siimplSauce,
      });

      console.log(updateUser);

      if (localStorage.getItem('user')) {
        const userAuth = JSON.parse(localStorage.getItem('user'));
        userAuth.siimplSauce = siimplSauce;
        localStorage.setItem('user', JSON.stringify(userAuth));
      }

      toast.info(`😃 User Pin updated!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const removeAllData = async () => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, get me outta here!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const accessToken = await getToken();

          fetch('/apiv2/removeAllData', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then(() => {
              setUserBookmarks([]);
              setUserCollections([]);
              Swal.fire({
                title: 'All Data has been deleted',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#2778c4',
                cancelButtonText: 'Close',
              });
              toast.info(`😃 All Data as been removed!`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      });
  };

  const removeAccount = async () => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, get me outta here!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const accessToken = await getToken();

          fetch(`/apiv2/removeAccount`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then(() => {
              history.push('https://siimpl.co');
              // auth.signOut().then(data => {
              //     history.push("/")
              // })
            });
        }
      });
  };

  const deleteDuplicates = async () => {
    setIsDeleting(true);
    const accessToken = await getToken();

    fetch(`/apiv2/deleteDuplicates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        toast.info(`😃 Removed duplicates!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => setIsDeleting(false));
  };

  const deleteAllDuplicates = async () => {
    const accessToken = await getToken();

    fetch(`/apiv2/deleteAllDuplicates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        toast.info(`😃 Removed all duplicates!`, {
          position: 'top-right',
          // autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const exportData = async () => {
    setIsExporting(true);
    const accessToken = await getToken();
    fetch(`/apiv2/getBookmarkSlim`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((bookmarks) => {
        let x = `<!DOCTYPE NETSCAPE-Bookmark-file-1>
            <!-- This is an automatically generated file.
                It will be read and overwritten.
                DO NOT EDIT! -->
            <META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=UTF-8">
            <TITLE>Siimpl Bookmark Export</TITLE>
            <H1>Siimpl Bookmark Export - ${bookmarks.length}</H1>
            <DL><p>
            <DT><H3 ADD_DATE="${Date.now()}" LAST_MODIFIED="${Date.now()}" PERSONAL_TOOLBAR_FOLDER="true">Siimpl Bookmark Export</H3>
            <DL><p>`;
        bookmarks.forEach(
          (el) =>
            (x += `<DT><A HREF="${
              el.BookmarkURL
            }" ADD_DATE="${Date.now()}" ICON="${el.Favicon}">${
              el.BookmarkName
            }</A>`),
        );
        x += `
            </DL><p>
            </DL><p>`;

        const element = document.createElement('a');
        element.setAttribute(
          'href',
          'data:text/html;charset=utf-8,' + encodeURIComponent(x),
        );
        element.setAttribute('download', 'Siimpl Export');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        setIsExporting(false);
      });
  };

  const onChangeHandler = (event) => {
    setImporting(true);
    const Collections = [];
    const Bookmarks = [];
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      const div = document.createElement('div');
      div.innerHTML = reader.result;
      const FolderName = div.querySelectorAll('h3');
      for (let i = 0; i < FolderName.length; i++) {
        Collections.push({
          CollectionId: uuidv4(),
          CollectionName: FolderName[i].innerText,
          isHidden: 0,
        });
      }

      const bookmark = div.querySelectorAll('a');
      for (let i = 0; i < bookmark.length; i++) {
        const bookmarkFolder =
          bookmark[i].closest('DL').previousSibling.previousElementSibling
            .innerText;
        const bookmarkData = {
          BookmarkName: bookmark[i].innerText.replace(/[^\w\s]/gi, ''),
          BookmarkURL: bookmark[i].getAttribute('href'),
          BookmarkThumbnail: bookmark[i].getAttribute('icon'),
          BookmarkType: 'page',
          Collection: Collections.filter(
            (el) => el.CollectionName === bookmarkFolder,
          )[0].CollectionId,
        };

        Bookmarks.push(bookmarkData);
      }
      setImporting(false);
    };
    reader.readAsText(event.target.files[0]);
  };

  return (
    <div style={{ height: 540 }}>
      {!isDangerZone ? (
        <div>
          <div
            style={{ position: 'absolute', right: 10, top: 10, fontSize: 11 }}
          >
            {pckg?.version}
          </div>
          <p>Account</p>

          <p className="m-0">
            <small>Avatar:</small>
          </p>
          <Button
            variant="link"
            onClick={() => inputEl.current.click()}
            style={{
              width: '60px',
              height: '60px',
              borderRadius: '100%',
              backgroundColor: 'dodgerblue',
              marginTop: 10,
              marginBottom: 10,
              padding: 0,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                backgroundImage: `url(${photoURL})`,
                backgroundSize: 'cover',
                backgroundColor: 'dodgerblue',
                height: '100%',
              }}
            ></div>
            <input
              type="file"
              ref={inputEl}
              onChange={(e) => onfileUpload(e)}
              style={{ display: 'none' }}
              accept="image/*"
            ></input>
          </Button>

          <p className="m-0">
            <small>Display name:</small>
          </p>
          <input
            type="text"
            placeholder="username"
            value={displayName || ''}
            onChange={(e) => {
              setDisplayName(e.target.value);
              setUsernameChange(true);
            }}
            onBlur={() => updateUsername()}
            spellCheck={false}
            autoComplete="off"
            style={{
              borderRadius: '5px',
              border: 'solid 1px #cbcbcb',
              height: '30px',
              padding: '8px',
              width: '280px',
              color: '#4d4d4d',
              fontWeight: 300,
            }}
          />
          <p className="m-0">
            <small>Hidden Collection Pin:</small>
          </p>
          <input
            type="text"
            placeholder="Pin"
            value={siimplSaucePin || ''}
            onChange={(e) => {
              setSiimplSauce(e.target.value);
              setSiimplSauceChange(true);
            }}
            onBlur={() => updateSiimplSauce()}
            spellCheck={false}
            autoComplete="off"
            style={{
              borderRadius: '5px',
              border: 'solid 1px #cbcbcb',
              height: '30px',
              padding: '8px',
              width: '280px',
              color: '#4d4d4d',
              fontWeight: 300,
            }}
          />

          <hr />
          <div
            className="mb-3"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <p className="m-0" style={{ fontSize: 14 }}>
                Import Bookmarks
              </p>
              <p className="m-0" style={{ fontSize: 11, color: 'grey' }}>
                import bookmarks from your favourite browsers or from other
                sites
              </p>
            </div>
            <div>
              <Button
                size="sm"
                className="m-0"
                onClick={() => fileInput.current.click()}
              >
                {importing ? 'Importing...' : 'Import'}
              </Button>
              <input
                ref={fileInput}
                type="file"
                name="file"
                onChange={(e) => onChangeHandler(e)}
                style={{ visibility: 'hidden', opacity: 0, width: 0 }}
              />
            </div>
          </div>
          <div
            className="mb-3"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <p className="m-0" style={{ fontSize: 14 }}>
                Export Bookmarks
              </p>
              <p className="m-0" style={{ fontSize: 11, color: 'grey' }}>
                Export your bookmarks for import into your browser
              </p>
            </div>
            <div>
              <Button size="sm" className="m-0" onClick={() => exportData()}>
                {isExporting ? 'Exporting...' : 'Export'}
              </Button>
            </div>
          </div>
          <div>
            <div
              style={{
                widht: '100%',
                height: 80,
                backgroundColor: '#f6a6a6',
                border: 'solid 1px #f29898',
                borderRadius: 5,
                padding: 10,
                display: 'flex',
                marginTop: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 'auto',
                  width: '100%',
                }}
              >
                <div>
                  <p className="m-0" style={{ fontSize: 14 }}>
                    Danger Zone
                  </p>
                  <p className="m-0" style={{ fontSize: 12 }}>
                    Delete duplicates, data or your account
                  </p>
                </div>
                <div>
                  <Button
                    size="sm"
                    variant="danger"
                    className="m-0"
                    style={{ borderRadius: 5 }}
                    onClick={() => setIsDangerZone(true)}
                  >
                    Enter Danger Zone
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              fontSize: 12,
              marginTop: 30,
            }}
          >
            <a href="https://siimpl.co/Help" target="_blank" rel="noreferrer">
              Help
            </a>
            <div style={{ margin: '0 10px 0 10px' }}>|</div>
            <a href="/Terms" target="_blank">
              Terms Of Service
            </a>
            <div style={{ margin: '0 10px 0 10px' }}>|</div>
            <a href="/Privacy" target="_blank">
              Privacy Policy
            </a>
            <div style={{ margin: '0 10px 0 10px' }}>|</div>
            <a href="/DataDeletion" target="_blank">
              Data Deletion
            </a>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="mb-5"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="m-0">Danger Zone</p>
            <Button
              variant="transparent"
              onClick={() => setIsDangerZone(false)}
              style={{ padding: 0, margin: 0 }}
            >
              X
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 40,
            }}
          >
            <div>
              <p className="m-0" style={{ fontSize: 14 }}>
                Remove Duplicates
              </p>
              <p className="m-0" style={{ fontSize: 11, color: 'grey' }}>
                Removes duplicate bookmarks in the same Collection.
              </p>
            </div>
            <div>
              <Button
                size="sm"
                className="m-0"
                variant="danger"
                style={{ borderRadius: 5 }}
                onClick={() => deleteDuplicates()}
              >
                {isDeleting ? 'Removing Duplicates...' : 'Remove Duplicates'}
              </Button>
            </div>
          </div>

          <hr />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 40,
            }}
          >
            <div>
              <p className="m-0" style={{ fontSize: 14 }}>
                Remove All Duplicates
              </p>
              <p className="m-0" style={{ fontSize: 11, color: 'grey' }}>
                Removes All duplicate bookmarks across all collections.
              </p>
            </div>
            <div>
              <Button
                size="sm"
                className="m-0"
                variant="danger"
                style={{ borderRadius: 5, fontSize: 12 }}
                onClick={() => deleteAllDuplicates()}
              >
                Remove ALL Duplicates
              </Button>
            </div>
          </div>

          <hr />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 40,
            }}
          >
            <div>
              <p className="m-0" style={{ fontSize: 14 }}>
                Delete All Data
              </p>
              <p className="m-0" style={{ fontSize: 11, color: 'grey' }}>
                Delete all your data but keep your account.
              </p>
            </div>
            <div>
              <Button
                size="sm"
                className="m-0"
                variant="danger"
                style={{ borderRadius: 5 }}
                onClick={() => removeAllData()}
              >
                Delete All Data
              </Button>
            </div>
          </div>

          <hr />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 40,
            }}
          >
            <div>
              <p className="m-0" style={{ fontSize: 14 }}>
                Delete Account
              </p>
              <p className="m-0" style={{ fontSize: 11, color: 'grey' }}>
                Delete your account and all your data.
              </p>
            </div>
            <div>
              <Button
                size="sm"
                className="m-0"
                variant="danger"
                style={{ borderRadius: 5 }}
                onClick={() => removeAccount()}
              >
                Delete Account
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import { useLocation } from "react-router"

export function escapeHtml (unsafe: string) : string {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export function validateEmail (email: string):  boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const generateAPIPath = (collectionId: string): string => {
  const uncategorized = 'Uncategorized'
  const collectionIsGuid =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      collectionId
    )

  if (collectionId && collectionId.toLowerCase() === 'trash') {
    return '/apiv2/getDeletedBookmark'
  } else if (collectionId && collectionId.toLowerCase() === 'home') {
    return '/apiv2/getRecentBookmarks'
  } else if (collectionIsGuid) {
    return '/apiv2/getBookmarkSlimByCollection'
  } else if (uncategorized) {
    return '/apiv2/getUncategorized'
  }

  return '/apiv2/getUncategorized'
}

export const getCollectionIdFromLocation = (): string | undefined => {
  const { pathname } = useLocation();

  const regex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gmi;
  const match = pathname.match(regex);
  return match?.[0];
}

export const newlineToHtmlBr = (str: string): string => {
  return str.split("\n").join("<br />")
}

export const htmlBrToNewline = (str: string): string => {
  return str.split("<br />").join("\n")
}

export const isValidURL = (bookmarkURL: string): boolean => {
  const isValid = /[a-zA-Z0-9]*\.[a-z]*/gi.test(bookmarkURL);
  return isValid
};
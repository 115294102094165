/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SnippetEditor from '../Actions/SnippetEditor';
import loadingGif from '../Assets/GIF1v7.gif';
import './TextSnippets.scss';
import SimpleBar from 'simplebar-react';
import { fetchAPI } from '../Helpers/fetchAPI.helper';
import { ReactComponent as TrashIcon } from '../Assets/feather/trash.svg';
import { ReactComponent as CodeIcon } from '../Assets/feather/code.svg';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SearchBar } from '../Components/Searchbar/Searchbar.style';
import Modal from '../Components/Modal/Modal.component';
import Swal from 'sweetalert2';

export default function TabCollections(): JSX.Element {
  const [isLoading, setLoading] = useState(true);
  const [isCodeSnippet, setIsCodeSnippet] = useState(false);

  const [notes, setNotes] = useState<any[]>([]);
  const [query, setQuery] = useState('');

  const [selectedTextSnippet, setSelectedTextSnippet] = useState(null);

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = (): void => {
    fetchAPI
      .get('/apiv2/getNotes')
      .then((data) => {
        if (window.screen.width > 1136) {
          setSelectedTextSnippet(data[0]);
        }

        setNotes(
          data.sort(
            (a: any, b: any) =>
              new Date(b.DateCreated).valueOf() -
              new Date(a.DateCreated).valueOf(),
          ),
        );
      })
      .finally(() => setLoading(false));
  };

  const moveToTrash = (selectedNoteId: string, index: number): void => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Are you sure you want to remove this text snippet?',
        text: 'This can not be undone',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, get me outta here!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          fetchAPI
            .post('/apiv2/removeNote', {
              headers: {
                noteId: selectedNoteId,
              },
            })
            .then(() => {
              const updatedArr = notes.filter((el) => el.Id !== selectedNoteId);
              setNotes(updatedArr);
              setSelectedTextSnippet(
                updatedArr[index - 1] || updatedArr[index + 1],
              );
            })
            .catch(console.log);
        }
      })
      .catch(console.error);
  };

  const updateTextSnippet = (snippet: any): void => {
    const notesArr = notes;
    const obj = notesArr.findIndex((el) => el.Id === snippet.Id);
    notesArr[obj].BookmarkName = snippet.BookmarkName;
    notesArr[obj].BookmarkBlurb = snippet.BookmarkBlurb;

    setNotes((prevState) =>
      prevState.map((item) =>
        item.Id === snippet.Id ? notesArr[obj] : { ...item },
      ),
    );
  };

  const convertToCode = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    item: any,
  ): void => {
    ev.preventDefault();
    ev.stopPropagation();

    const snippet = {
      ...item,
      isCodeSnippet: !isCodeSnippet,
    };

    setSelectedTextSnippet(snippet);
    setIsCodeSnippet(!isCodeSnippet);
  };

  const snippetResults = notes.filter(
    (note) =>
      note?.BookmarkName?.toLowerCase().includes(query?.toLowerCase()) ||
      note?.BookmarkBlurb?.toLowerCase().includes(query?.toLowerCase()),
  );

  if (isLoading) {
    return (
      <div className="d-flex loading-bookmarks" style={{ height: '75vh' }}>
        <img src={loadingGif} />
      </div>
    );
  }

  return (
    <div id="text-snippets">
      <div className="snippet-selector">
        <div className="snippet-selector-header">
          <SearchBar
            type="search"
            placeholder="Search snippets..."
            value={query}
            onChange={(e: { target: { value: string } }) =>
              setQuery(e.target.value)
            }
          />
        </div>
        <SimpleBar style={{ maxHeight: 'calc(100vh - 100px)' }} autoHide>
          {snippetResults.map((item, index) => (
            <div
              key={index}
              className={`snippet-item ${
                selectedTextSnippet === item ? 'snippet-item--active' : ''
              }`}
              onClick={() => setSelectedTextSnippet(item)}
            >
              <div className="siimpl-item-wrapper">
                <h6>{item.BookmarkName}</h6>
                <p className="note-content">{unescape(item.BookmarkBlurb)}</p>
                <div className="details">
                  <span className="date">
                    <small>{moment(item.DateCreated).format('ll')}</small>
                  </span>
                  <div>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">Remove snippet</Tooltip>
                      }
                    >
                      <Button
                        className="remove-snippet"
                        variant="link"
                        onClick={() => moveToTrash(item.Id, index)}
                      >
                        <TrashIcon />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Toggle code mode
                        </Tooltip>
                      }
                    >
                      <Button
                        className="code-snippet"
                        variant="link"
                        onClick={(ev) => convertToCode(ev, item)}
                      >
                        <CodeIcon />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </SimpleBar>
      </div>

      <div className="snippet-viewer">
        <SnippetEditor
          selectedTextSnippet={selectedTextSnippet}
          updateTextSnippet={(snippet: any) => updateTextSnippet(snippet)}
        />
      </div>

      <Modal
        className="mobile-text-snippet"
        show={!!selectedTextSnippet}
        onClose={() => {
          setSelectedTextSnippet(null);
        }}
        maxWidth={600}
      >
        <SnippetEditor
          selectedTextSnippet={selectedTextSnippet}
          updateTextSnippet={(snippet: any) => updateTextSnippet(snippet)}
        />
      </Modal>
    </div>
  );
}

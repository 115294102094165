import React from 'react';
import AppleStoreLogo from '../Assets/appstore.png';
import GooglePlayLogo from '../Assets/playstore.png';

import './EmptyBookmark.scss';

export default function EmptyBookmark(props) {
  const collectionIsGuid =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      props.activeCollection,
    );

  if (collectionIsGuid) {
    return (
      <div id="empty">
        <div
          className="m-auto text-center"
          style={{ color: '#cccccc', fontWeight: 200 }}
        >
          <p className="m-auto" style={{ fontSize: 100, color: '#e6e6e6' }}>
            Siimpl
          </p>
          <p className="mb-0" style={{ fontSize: 30 }}>
            For the best experience use our browser extensions or app to save
            bookmarks.
          </p>
          <ul className="platforms-list list-inline my-1">
            <li className="list-inline-item">
              <a
                href="https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/240px-Google_Chrome_icon_%28September_2014%29.svg.png"
                  alt=""
                  className="platform-image img-fluid"
                />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://cdn.vox-cdn.com/thumbor/VeEPEECslTmxDJwzV2RHXhslYpg=/0x0:1980x1320/920x613/filters:focal(832x502:1148x818):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/65613211/microsoftedgenewlogo.5.jpg"
                  alt=""
                  className="platform-image img-fluid"
                />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://addons.mozilla.org/en-CA/firefox/addon/siimpl-bookmarking/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Firefox_Project_Logo%2C_2019.svg/1024px-Firefox_Project_Logo%2C_2019.svg.png"
                  alt=""
                  className="platform-image img-fluid"
                />
              </a>
            </li>
          </ul>
          <a
            href="https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621"
            target="_blank"
            rel="noreferrer"
            className="mobile-app"
          >
            <img src={AppleStoreLogo} alt="" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.siimpl"
            target="_blank"
            rel="noreferrer"
            className="mobile-app"
          >
            <img src={GooglePlayLogo} alt="" className="ml-2" />
          </a>
          <p className="mb-0" style={{ fontSize: 20 }}>
            or you can add bookmarks using the plus button above.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div id="empty">
      <div
        className="m-auto text-center"
        style={{ color: '#cccccc', fontWeight: 200 }}
      >
        <p className="m-auto" style={{ fontSize: 100, color: '#e6e6e6' }}>
          Siimpl
        </p>
        <p className="mb-0" style={{ fontSize: 30 }}>
          Bookmarks from our app and extensions will show here.
        </p>
        <ul className="platforms-list list-inline my-1">
          <li className="list-inline-item">
            <a
              href="https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/240px-Google_Chrome_icon_%28September_2014%29.svg.png"
                alt=""
                className="platform-image img-fluid"
              />
            </a>
          </li>
          <li className="list-inline-item">
            <a
              href="https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://cdn.vox-cdn.com/thumbor/VeEPEECslTmxDJwzV2RHXhslYpg=/0x0:1980x1320/920x613/filters:focal(832x502:1148x818):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/65613211/microsoftedgenewlogo.5.jpg"
                alt=""
                className="platform-image img-fluid"
              />
            </a>
          </li>
          <li className="list-inline-item">
            <a
              href="https://addons.mozilla.org/en-CA/firefox/addon/siimpl-bookmarking/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Firefox_Project_Logo%2C_2019.svg/1024px-Firefox_Project_Logo%2C_2019.svg.png"
                alt=""
                className="platform-image img-fluid"
              />
            </a>
          </li>
        </ul>
        <a
          href="https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621"
          target="_blank"
          rel="noreferrer"
          className="mobile-app"
        >
          <img src={AppleStoreLogo} alt="" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.siimpl"
          target="_blank"
          rel="noreferrer"
          className="mobile-app"
        >
          <img src={GooglePlayLogo} alt="" className="ml-2" />
        </a>
        <p className="mb-0" style={{ fontSize: 30 }}>
          To add your own from this web app please create a collection
        </p>
      </div>
    </div>
  );
}

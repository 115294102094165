/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import BookmarkEditor from '../../Actions/BookmarkEditor';
import CollectionEditor from '../../Actions/CollectionEditor';
import Modal from '../Modal/Modal.component';

export const SiimplAdd = (): JSX.Element => {
  const [showCollectionCreator, setShowCollectionCreator] = useState(false);
  const [showBookmarkCreator, setShowBookmarkCreator] = useState(false);

  return (
    <>
      <DropdownButton variant="primary" title="Add" menuAlign="right">
        <Dropdown.Item onClick={() => setShowCollectionCreator(true)}>
          Add Collection
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowBookmarkCreator(true)}>
          Add Bookmark
        </Dropdown.Item>
      </DropdownButton>

      <Modal
        show={showCollectionCreator}
        onClose={() => {
          setShowCollectionCreator(false);
        }}
      >
        <CollectionEditor
          collection={null}
          isAdd={true}
          close={() => setShowCollectionCreator(false)}
        />
      </Modal>

      <Modal
        show={showBookmarkCreator}
        onClose={() => setShowBookmarkCreator(false)}
        maxWidth={460}
      >
        <BookmarkEditor
          isAdd={true}
          close={() => setShowBookmarkCreator(false)}
        />
      </Modal>
    </>
  );
};

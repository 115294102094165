import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { BookmarkCard } from '../Components/BookmarkCard/BookmarkCard';
import './BookmarkMasonry.scss';

const breakpointColumnsObj = {
  default: 6,
  1800: 5,
  1600: 4,
  700: 2,
  100: 1,
};

export default function BookmarkList(props) {
  const [isGridLayout, setIsGridLayout] = useState(false);
  const [activeCollection, setActiveCollection] = useState(null);
  const [collectionBookmarks, setCollectionBookmarks] = useState([]);
  const [collectionBookmarkPlaceholder, setCollectionBookmarkPlaceholder] =
    useState([]);
  const [selectedBookmarks, setSelectedBookmarks] = useState([]);

  useEffect(() => {
    if (props.collectionBookmarks !== collectionBookmarks) {
      setCollectionBookmarks(props.collectionBookmarks);
    }
  }, [props.collectionBookmarks]);

  useEffect(() => {
    if (props.collectionBookmarkPlaceholder !== collectionBookmarkPlaceholder) {
      setCollectionBookmarkPlaceholder(props.collectionBookmarkPlaceholder);
    }
  }, [props.collectionBookmarkPlaceholder]);

  useEffect(() => {
    if (props.activeCollection !== activeCollection) {
      setActiveCollection(props.activeCollection);
    }
  }, [props.activeCollection]);

  useEffect(() => {
    if (props.selectedBookmarks !== selectedBookmarks) {
      setSelectedBookmarks(props.selectedBookmarks);
    }
  }, [props.selectedBookmarks]);

  useEffect(() => {
    if (props.isGridLayout !== isGridLayout) {
      setIsGridLayout(props.isGridLayout);
    }
  }, [props.isGridLayout]);

  const drag = (ev) => {
    if (selectedBookmarks.length > 1) {
      const elem = document.createElement('div');
      elem.id = 'drag-ghost';
      elem.textNode = 'Dragging';
      elem.style.position = 'absolute';
      elem.style.top = '-1000px';
      elem.innerHTML = `<h6 className="dragging-multiple">Multiple ${selectedBookmarks.length} Bookmarks<h6>`;
      document.body.appendChild(elem);
      ev.dataTransfer.setDragImage(elem, 50, 0);
      ev.dataTransfer.setData('bookmarks', JSON.stringify(selectedBookmarks));
    } else {
      ev.dataTransfer.setData(
        'text',
        ev.target.closest('.draggable-card').getAttribute('id'),
      );
    }
  };

  return (
    <div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid bookmark-grid"
        columnClassName="my-masonry-grid_column"
      >
        {collectionBookmarks.map((item, index) => (
          <div
            id={item.Id}
            key={index}
            draggable={true}
            className="draggable-card"
            onDragStart={(e) => drag(e)}
          >
            <BookmarkCard
              bookmark={item}
              isPreview={false}
              activeCollectionDetails={props.activeCollectionDetails}
              addToSelected={props.addToSelected}
              setSingleSelectedBookmark={props.setSingleSelectedBookmark}
              setSingleSelectedBookmarkCopy={
                props.setSingleSelectedBookmarkCopy
              }
              setShowBookmarkEditor={props.setShowBookmarkEditor}
              moveToTrash={props.moveToTrash}
              showReader={props.showReader}
              selectedBookmarks={selectedBookmarks}
              isGridLayout={isGridLayout}
              openImageLightbox={(e) => props.openImageLightbox(e)}
              {...props}
            />
          </div>
        ))}
      </Masonry>
    </div>
  );
}

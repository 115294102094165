import React from 'react'

interface OverlayProps {
  isActive: boolean
  handleClick: () => void
}

export const Overlay = (props: OverlayProps): JSX.Element => {
  const { isActive, handleClick } = props

  return (
		<div
			className={isActive ? 'overlay active' : 'overlay'}
			onClick={handleClick}
		></div>
  )
}

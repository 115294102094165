import React from 'react';
import UserProvider from './providers/UserProvider.provider';
import ApplicationContainer from './ApplicationContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import { ErrorBoundary } from './Components/ErrorBoundary/ErrorBoundary.component';

function App(): JSX.Element {
  return (
    <ErrorBoundary>
      <UserProvider>
        <ToastContainer />
        <ApplicationContainer />
      </UserProvider>
    </ErrorBoundary>
  );
}

export default App;

import React, { useState } from 'react';
import {
  generateUserDocument,
  signInWithGoogle,
  signInWithApple,
  signInWithEmail,
} from './firebase';
import { FormControl, Form, Button } from 'react-bootstrap';
import { auth, sendToExtension } from './firebase';

import './Auth.scss';
import bg from '../Assets/Login_bg.jpg';
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
} from 'firebase/auth';

const AuthMain = (props) => {
  const googleAuth = async () => {
    await signInWithGoogle();
  };

  const appleAuth = async () => {
    await signInWithApple();
  };

  return (
    <>
      <h1>Are you ready to start collecting and organizing?</h1>

      <Button className="google-login" onClick={() => googleAuth()}>
        <img
          width="14px"
          alt="Google sign-in"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
        />
        <span>Continue with Google</span>
      </Button>

      <Button className="google-login" onClick={() => appleAuth()}>
        <img
          width="14px"
          alt="Apple sign-in"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1010px-Apple_logo_black.svg.png"
        />
        <span>Continue with Apple</span>
      </Button>
      {/* <small className='d-flex justify-content-center'>Apple Login temporarily disabled - we should have it back up soon</small> */}

      <Button
        variant="link"
        className="email-login"
        onClick={() => props.authType('Register')}
      >
        <span>
          OR JOIN WITH <strong>EMAIL</strong>
        </span>
      </Button>

      <Button
        variant="link"
        className="login"
        onClick={() => props.authType('Login')}
      >
        <span>
          ALREADY A MEMBER? <strong>LOG IN</strong>
        </span>
      </Button>
    </>
  );
};

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const forgotPassword = () => {
    if (email === '') {
      setError('Enter email above and hit Forgot Password');
    } else {
      setError(`Reset email has been sent to ${email}`);
    }
    return auth.sendPasswordResetEmail(email);
  };

  return (
    <div className="auth_signin">
      <h1>Sign In</h1>
      <Form
        className="p-3"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          signInWithEmail({ email, password }).catch(
            setError('Error signing in with password and email!'),
          );
        }}
      >
        <FormControl
          aria-label="Small"
          type="email"
          name="email"
          placeholder="Email Address..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          aria-describedby="inputGroup-sizing-sm"
        />
        <FormControl
          aria-label="Small"
          type="password"
          placeholder="Password..."
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-2"
          aria-describedby="inputGroup-sizing-sm"
        />
        <Button
          variant="link"
          className="forgot_btn"
          onClick={() => forgotPassword()}
        >
          Forgot Password?
        </Button>
        {error && (
          <div
            className="mb-3 text-center error-msg"
            style={{ color: '#b73c3c' }}
          >
            {error}
          </div>
        )}
        <Button className="sign_in_btn" type="submit">
          SIGN IN
        </Button>

        <Button
          variant="link"
          className="email-login"
          onClick={() => props.authType('Register')}
        >
          <span>
            OR JOIN WITH <strong>EMAIL</strong>
          </span>
        </Button>
      </Form>
    </div>
  );
};

const Register = (props) => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      createUserWithEmailAndPasswordHandler(event, email, password);
    }

    setValidated(true);
  };

  const createUserWithEmailAndPasswordHandler = async (
    event,
    email,
    password,
  ) => {
    event.preventDefault();
    try {
      createUserWithEmailAndPassword(auth, email, password).then((user) => {
        const providerName = 'email';
        const authCredential = EmailAuthProvider.credential(email, password);
        sendToExtension(authCredential, providerName);
        generateUserDocument(user, { displayName });
      });
    } catch (error) {
      console.log(error);
      setError('Error Signing up with email and password');
    }
  };

  return (
    <div className="auth_register">
      <h1>Sign up</h1>
      <Form
        noValidate
        validated={validated}
        className="p-3"
        onSubmit={(e) => handleSubmit(e)}
      >
        <FormControl
          aria-label="Small"
          type="text"
          placeholder="Display Name..."
          name="displayName"
          value={displayName}
          required
          onChange={(e) => setDisplayName(e.target.value)}
          aria-describedby="inputGroup-sizing-sm"
        />
        <FormControl
          aria-label="Small"
          type="email"
          placeholder="Email Address..."
          name="email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
          aria-describedby="inputGroup-sizing-sm"
        />
        <FormControl
          aria-label="Small"
          type="password"
          minlength="6"
          placeholder="Password..."
          name="password"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
          aria-describedby="inputGroup-sizing-sm"
        />
        <Button className="sign_in_btn" type="submit">
          JOIN
        </Button>

        <Button
          variant="link"
          className="login my-3"
          onClick={() => props.authType('Login')}
        >
          <span>
            ALREADY A MEMBER? <strong>LOG IN</strong>
          </span>
        </Button>
      </Form>

      {error && (
        <div className="mb-3 text-center" style={{ color: '#b73c3c' }}>
          {error}
        </div>
      )}
    </div>
  );
};

const Auth = () => {
  const [authType, setAuthType] = useState(null);

  return (
    <div id="auth_screen">
      <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
      <div className="auth-container">
        <div className="login-option-container">
          {authType === 'Login' ? (
            <Login authType={(type) => setAuthType(type)} />
          ) : authType === 'Register' ? (
            <Register authType={(type) => setAuthType(type)} />
          ) : (
            <AuthMain authType={(type) => setAuthType(type)} />
          )}

          <p className="agree">
            By creating an account you agree to our{' '}
            <Button variant="link" href="/Terms" target="_blank">
              Terms
            </Button>{' '}
            and{' '}
            <Button variant="link" href="/Privacy" target="_blank">
              Privacy Policy
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Auth;

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import '../BookmarkCard/BookmarkCard.scss';
import Modal from '../../Components/Modal/Modal.component';
import BookmarkMover from '../../Components/BookmarkMover/BookmarkMover.component';
import { DataContext } from '../../Context/DataProvider';
import BookmarkEditor from '../../Actions/BookmarkEditor';
import { BookmarkType } from '../../Types/BookmarkType';
import { ActiveCollectionDetailsTypes } from '../../Types/CollectionTypes';

interface BookmarkCardProps {
  bookmark: BookmarkType;
  activeCollectionDetails: ActiveCollectionDetailsTypes;
  addToSelected: (id: string) => void;
  moveToTrash: (id: number) => void;
  selectedBookmarks: string[];
  isGridLayout: boolean;
  openImageLightbox: (id: string) => void;
}

export const BookmarkCard = (props: BookmarkCardProps): JSX.Element => {
  const {
    bookmark,
    activeCollectionDetails,
    addToSelected,
    moveToTrash,
    selectedBookmarks,
    isGridLayout,
    openImageLightbox,
  } = props;
  const { userCollections } = useContext(DataContext);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [showBookmarkEditor, setShowBookmarkEditor] = useState(false);
  const [imageFailedLoad, setImageFailedLoad] = useState(false);

  const { id: activeCollection } = useParams<{ id: string }>();

  const decode = (blurb: string): string => {
    if (blurb?.includes('%20')) {
      return decodeURIComponent(blurb);
    }
    return blurb;
  };

  const getHostName = (bookmarkUrl: BookmarkType['BookmarkURL']): string => {
    if (!bookmarkUrl) {
      return bookmarkUrl;
    }

    try {
      const url = new URL(bookmarkUrl);
      return url?.hostname;
    } catch {
      return bookmarkUrl;
    }
  };

  const openBookmark = (bookmark: BookmarkType): void => {
    bookmark.BookmarkType === 'image'
      ? openImageLightbox(bookmark.Id)
      : window.open(bookmark.BookmarkURL, '_blank');
  };

  const active = selectedBookmarks.includes(bookmark.Id);

  return (
    <div className="preview" data-grid={isGridLayout && 'grid'}>
      <div
        className={`preview-inner ${active ? 'selected' : ''}`}
        onClick={() => selectedBookmarks.length && addToSelected(bookmark.Id)}
      >
        <div className="img-container" onClick={() => openBookmark(bookmark)}>
          {!imageFailedLoad && (
            <img
              src={bookmark.BookmarkImage}
              onError={() => {
                setImageFailedLoad(true);
              }}
            />
          )}
        </div>

        <p className="card-title">{bookmark.BookmarkName}</p>

        <div className="link">
          <a
            className="card-url"
            href={bookmark.BookmarkURL}
            rel="noreferrer"
            target="_blank"
          >
            {getHostName(bookmark.BookmarkURL)}
          </a>
        </div>
        <p className="card-wording">{decode(bookmark.BookmarkBlurb)}</p>
        <div className="card-foot d-flex w-100">
          <div className="position-relative w-100">
            <div className="ml-auto option-container">
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle variant="link" className="text-muted">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="19" cy="12" r="1"></circle>
                    <circle cx="5" cy="12" r="1"></circle>
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey="3"
                    onClick={() => addToSelected(bookmark.Id)}
                  >
                    Select
                  </Dropdown.Item>
                  {activeCollectionDetails?.isEditable === 1 && (
                    <Dropdown.Item
                      eventKey="5"
                      onClick={() => {
                        setShowMoveModal(true);
                        setIsCopy(false);
                      }}
                    >
                      Move to Channel
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    eventKey="3"
                    onClick={() => {
                      setShowMoveModal(true);
                      setIsCopy(true);
                    }}
                  >
                    Copy to Channel
                  </Dropdown.Item>
                  {activeCollectionDetails?.isEditable === 1 && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        eventKey="4"
                        onClick={() => setShowBookmarkEditor(true)}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="5"
                        onClick={() => moveToTrash(parseInt(bookmark.Id))}
                      >
                        Remove
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {(!activeCollectionDetails ||
            (activeCollectionDetails?.CollectionName || '')?.toLowerCase() ===
              'home') && (
            <Link
              to={`/b/collection/${bookmark.Collection}`}
              className="home-screen-collection-indicator"
            >
              <small className="text-small">{bookmark.CollectionName}</small>
            </Link>
          )}
        </div>
      </div>

      <Modal
        show={showMoveModal}
        onClose={() => {
          setShowMoveModal(false);
        }}
        maxWidth={570}
      >
        <BookmarkMover
          id={bookmark.Id}
          isCopy={isCopy}
          userCollections={userCollections}
          activeCollection={activeCollection}
          onClose={() => setShowMoveModal(false)}
        />
      </Modal>

      <Modal
        show={showBookmarkEditor}
        onClose={() => {
          setShowBookmarkEditor(false);
        }}
        maxWidth={460}
      >
        <BookmarkEditor
          bookmark={bookmark}
          close={() => setShowBookmarkEditor(false)}
        />
      </Modal>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import './Public.scss';
import loadingGif from '../Assets/GIF1v7.gif';
import '../WebSite/Public.scss';
import { fetchAPI } from '../Helpers/fetchAPI.helper';
import { BookmarkType } from '../Types/BookmarkType';
import { CollectionTypes } from '../Types/CollectionTypes';

interface ImageProps {
  Id: string;
  src: string;
}

const breakpointColumnsObj = {
  default: 5,
  1800: 5,
  1600: 4,
  700: 2,
  100: 1,
};

export default function Bookmarks(): JSX.Element {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [collectionBookmarks, setCollectionBookmarks] = useState<
    BookmarkType[]
  >([]);
  const [filteredBookmarks, setFilteredBookmarks] = useState<BookmarkType[]>(
    [],
  );
  const [nestedCollections, setNestedCollections] = useState<any[]>([]);
  const [images, setImages] = useState<ImageProps[]>([]);
  const [activeCollection, setActiveCollection] = useState<string>('');

  const [photoIndex, setPhotoIndex] = useState(0);
  const chosenCollection = useRef(null);
  const { id } = useParams<any>();

  const searchResults: BookmarkType[] = searchQuery
    ? filteredBookmarks.filter((bookmark) =>
        bookmark.BookmarkName?.toLocaleLowerCase().includes(
          searchQuery.toLowerCase(),
        ),
      )
    : filteredBookmarks;

  useEffect(() => {
    getBookmarks(id);
  }, [id]);

  const getBookmarks = (collectionId: string): void => {
    setIsLoading(true);

    fetchAPI
      .get(`/api/getCollectionPublic`, {
        headers: {
          collectionid: collectionId,
        },
      })
      .then((data) => {
        if (!data.length) {
          setNoAccess(true);
          setIsLoading(false);
          return;
        }
        chosenCollection.current = data?.[0]?.CollectionName || '';

        const images = data.reduce(
          (arr: any[], curr: any) =>
            curr.BookmarkType === 'image'
              ? [
                  {
                    Id: curr.Id,
                    src: curr.BookmarkImage,
                  },
                  ...arr,
                ]
              : arr,
          [],
        );

        setImages(images);
        setIsLoading(false);
        if (data.length) {
          getNested(collectionId, data[0].Collection);
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  const openImageLightbox = (Id: string): void => {
    const obj = images.findIndex((el) => el.Id === Id);
    setPhotoIndex(obj);
    setIsOpen(true);
  };

  const getNested = (collectionId: string, parentCollectionId: any): void => {
    fetchAPI
      .get(`/api/getPublicNestedCollection`, {
        headers: {
          collectionid: collectionId,
        },
      })
      .then((data) => {
        const nestedCollections = data.map((collection: CollectionTypes) => {
          return {
            collectionId: collection.CollectionId,
            collectionName: collection.CollectionName,
          };
        });

        setNestedCollections(Array.from(new Set(nestedCollections)));

        setCollectionBookmarks((prevState) => [...data, ...prevState]);
        setFilteredBookmarks((prevState) =>
          [...data, ...prevState].filter(
            (el) => el.CollectionId === parentCollectionId,
          ),
        );
      })
      .catch(console.error);
  };

  const changeActiveCollection = (collectionId: string): void => {
    setActiveCollection(collectionId);
    const collections = collectionBookmarks.filter(
      (el) => el.Collection === collectionId,
    );
    setFilteredBookmarks(collections);
  };

  if (isLoading) {
    return (
      <div className="d-flex loading-bookmarks" style={{ height: '75vh' }}>
        <img alt="siimpl loading icon" src={loadingGif} />
      </div>
    );
  }

  if (noAccess) {
    return (
      <div
        className="d-flex flex-column loading-bookmarks"
        style={{ height: '75vh' }}
      >
        <div className="text-center m-auto">
          <img alt="siimpl loading icon mb-0" src={loadingGif} />
          <h3 className="mx-auto mt-0 w-75" style={{ fontWeight: '200' }}>
            Page either doesn&apos;t exist or you dont have access.
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div id="public-collection">
      <div className="header d-flex">
        <div className="title">
          <p
            className="styled-header"
            style={
              (chosenCollection?.current || '').length > 35
                ? { fontSize: '1.8rem' }
                : {}
            }
          >
            {chosenCollection.current}
          </p>
          <div className="header-underline"></div>
        </div>
        <form
          role="search"
          id="form"
          className="d-flex m-auto"
          autoComplete="off"
        >
          <input
            type="search"
            id="query"
            name="q"
            aria-label="Search through site content"
            className="public-search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button>
            <svg viewBox="0 0 1024 1024">
              <path
                className="path1"
                d="M848.471 928l-263.059-263.059c-48.941 36.706-110.118 55.059-177.412 55.059-171.294 0-312-140.706-312-312s140.706-312 312-312c171.294 0 312 140.706 312 312 0 67.294-24.471 128.471-55.059 177.412l263.059 263.059-79.529 79.529zM189.623 408.078c0 121.364 97.091 218.455 218.455 218.455s218.455-97.091 218.455-218.455c0-121.364-103.159-218.455-218.455-218.455-121.364 0-218.455 97.091-218.455 218.455z"
              ></path>
            </svg>
          </button>
        </form>
        <div className="d-flex">
          <Button
            variant="link"
            className="outlined-button text-muted"
            href="https://siimpl.co"
            target="_blank"
          >
            <p className="m-auto">Create your own collection</p>
          </Button>
        </div>
      </div>

      <div className="collecton-metrics d-flex justify-content-between">
        <p className="my-auto mb-0 text-muted">
          Bookmarks <strong>{collectionBookmarks.length}</strong>
        </p>
      </div>

      <div>
        {nestedCollections.map((item, index) => (
          <OverlayTrigger
            key={index}
            placement={'top'}
            overlay={<Tooltip id={''}>{item.collectionName}</Tooltip>}
          >
            <Button
              variant="link"
              className={
                activeCollection === item.collectionId
                  ? 'nested__collection active'
                  : 'nested__collection'
              }
              onClick={() => changeActiveCollection(item.collectionId)}
            >
              <svg
                viewBox="0 0 24 24"
                width="95"
                height="95"
                stroke="#e2e2e2"
                strokeWidth="2"
                fill="#e2e2e2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <p className="text-muted">{item.collectionName}</p>
            </Button>
          </OverlayTrigger>
        ))}
      </div>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid-public bookmark-grid p-0"
        columnClassName="my-masonry-grid_column"
      >
        {searchResults.map((item, index) => (
          <div
            className={
              item.BookmarkType === 'image'
                ? 'siimpl-item image'
                : 'siimpl-item'
            }
            id={item.Id}
            key={index}
            onClick={() =>
              item.BookmarkType === 'image'
                ? openImageLightbox(item.Id)
                : window.open(item.BookmarkURL, '_blank')
            }
          >
            <div className={'siimpl-img'}>
              <img
                src={
                  (item.AppleTouchIcon ?? item.OGimage) || item.BookmarkImage
                }
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null;
                  target.src =
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                  target.className = 'empty-img';
                }}
                alt="website icon"
              />
            </div>
            <div className="siimpl-item-details">
              <div
                className={
                  item.BookmarkType === 'image'
                    ? 'title clamp-one-line'
                    : 'title'
                }
              >
                <p>{item.BookmarkName}</p>
              </div>
              <p className="description">{item.BookmarkBlurb}</p>
              <div className="details d-flex justify-content-between">
                <div className="color"></div>
                <small>{moment(item.DateCreated).format('ll')}</small>
              </div>
            </div>
          </div>
        ))}
      </Masonry>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}

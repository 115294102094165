import React, { useContext, useMemo, useState } from 'react';
import { UserCollectionProps } from '../SideNavigation/SideNavigation.component';
import 'simplebar/dist/simplebar.min.css';

import { fetchAPI } from '../../Helpers/fetchAPI.helper';
import { DataContext } from '../../Context/DataProvider';
import { Bookmark } from '../../defintions/Bookmark.defintion';
import {
  BookmarkMoverHead,
  CollectionItem,
  InputContainer,
  MoverContainer,
} from './BookmarkMover.style';
import { toast } from 'react-toastify';

interface BookmarkMoverProps {
  id: string | string[];
  userCollections: UserCollectionProps[];
  activeCollection: UserCollectionProps['CollectionId'];
  onClose?: () => void;
  isCopy: boolean;
}

export default function BookmarkMover(props: BookmarkMoverProps): JSX.Element {
  const { id, onClose, userCollections, activeCollection, isCopy } = props;
  const { collectionBookmarks, setCollectionBookmarks } =
    useContext(DataContext);
  const [searchQuery, setSearchQuery] = useState('');

  const collections = useMemo(
    () =>
      userCollections.filter(
        (el) => el.isEditable === 1 && el.CollectionId !== activeCollection,
      ),
    [userCollections],
  );

  const collectionResults = useMemo(() => {
    if (!searchQuery) {
      return collections;
    }

    return collections.filter((collection) =>
      collection?.CollectionName?.toLowerCase().includes(
        searchQuery.toLowerCase(),
      ),
    );
  }, [searchQuery]);

  const moveBookmark = (collectionId: string): void => {
    const idsToMoveCopy = Array.isArray(id) ? id : [id];
    const collectionDetails = {
      moveTo: collectionId,
      bookmarkIds: idsToMoveCopy,
    };

    if (!isCopy) {
      setCollectionBookmarks(
        collectionBookmarks.filter(
          (bookmark: Bookmark) => !idsToMoveCopy.includes(bookmark.Id),
        ),
      );
    }

    const apiPath = isCopy
      ? '/apiv2/copyMultipleBookmarks'
      : '/apiv2/moveMultipleBookmarks';

    fetchAPI
      .post(apiPath, {
        body: collectionDetails,
      })
      .catch(console.log)
      .finally(() => {
        onClose?.();
        toast.success(`Bookmark has been ${isCopy ? 'Copied' : 'Moved'}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: 'light',
        });
      });
  };

  return (
    <div>
      <BookmarkMoverHead>
        <h6>{isCopy ? 'Copy' : 'Move'} to Channel</h6>

        <InputContainer>
          <input
            type="text"
            placeholder="Search Collections"
            className="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            autoFocus
            spellCheck={false}
            autoComplete="off"
          />
        </InputContainer>
      </BookmarkMoverHead>
      <MoverContainer>
        {collectionResults.map((item, index) => (
          <CollectionItem
            key={index}
            onClick={() => moveBookmark(item.CollectionId)}
          >
            <p>{item.CollectionName}</p>
          </CollectionItem>
        ))}
      </MoverContainer>
    </div>
  );
}

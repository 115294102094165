import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { DataContext } from '../Context/DataProvider';
import { getToken } from '../Auth/firebase';
import Swal from 'sweetalert2';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { ReactComponent as LockIcon } from '../Assets/svg/lock.svg';
import { ReactComponent as XIcon } from '../Assets/svg/x.svg';
import { ReactComponent as ShareIcon } from '../Assets/feather/share-2.svg';
import Modal from '../Components/Modal/Modal.component';
import { generateAPIPath } from '../Helpers/helpers';

import {
  Button,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import EmptyTrash from '../Actions/EmptyTrash';

import CollectionEditor from '../Actions/CollectionEditor';

import BookmarkMasonry from '../Elements/BookmarkMasonry';

import loadingGif from '../Assets/GIF1v7.gif';
import EmptyBookmark from '../Elements/EmptyBookmark';
import { fetchAPI } from '../Helpers/fetchAPI.helper';
import { CollectionSharer } from '../Components/CollectionSharer/CollectionSharer.component';
import { MultiSelect } from '../Components/MultiSelect/MultiSelect.component';

export default function Bookmarks() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showCollectionCreator, setShowCollectionCreator] = useState(false);
  const [showCollectionSharer, setShowCollectionSharer] = useState(false);

  const [activeCollection, setActiveCollection] = useState(null);
  const [activeCollectionDetails, setActiveCollectionDetails] = useState(null);
  const [, setSortTime] = useState(new Date());

  const [selectedBookmarks, setSelectedBookmarks] = useState([]);
  const [images, setImages] = useState([]);

  const {
    userCollections,
    setUserCollections,
    socket,
    collectionBookmarks,
    setCollectionBookmarks,
  } = useContext(DataContext);

  const [photoIndex, setPhotoIndex] = useState(0);

  const chosenCollection = useRef(null);
  const history = useHistory();

  const { id } = useParams();

  const updatedBookmark = (data) => {
    const incomingBookmarkData = JSON.parse(data || {});
    setCollectionBookmarks((bookmarks) =>
      bookmarks.map((bookmark) => {
        console.log(bookmark);
        return bookmark.Id === incomingBookmarkData.Id
          ? incomingBookmarkData
          : bookmark;
      }),
    );
  };

  useEffect(() => {
    if (socket) {
      socket.on('BookmarkAdded', addedBookmark);
      socket.on('BookmarkUpdated', updatedBookmark);
    }
  }, [socket]);

  const addedBookmark = async (data) => {
    const bookmarkArr = JSON.parse(data);

    setActiveCollection((prevState) => (chosenCollection.current = prevState));
    if (
      bookmarkArr.Collection === chosenCollection.current ||
      chosenCollection.current === 'home'
    ) {
      setCollectionBookmarks((prevState) => {
        const exists = prevState.findIndex((el) => el.Id === bookmarkArr.Id);

        if (exists < 0) {
          setCollectionBookmarks([bookmarkArr, ...prevState]);
        } else {
          const x = prevState.map((el) =>
            el.Id === bookmarkArr.Id ? bookmarkArr : el,
          );
          // console.log(x)
          setCollectionBookmarks(x);
        }
      });
    }
  };

  const defaultRoutes = ['home', 'uncategorized', 'trash'];

  useEffect(() => {
    if (id !== activeCollection) {
      setActiveCollection(id);
      const collectionIsGuid =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
          id,
        );
      if (collectionIsGuid) {
        if (userCollections?.length) {
          const activeCollectionData =
            userCollections.filter((el) => el.CollectionId === id) &&
            userCollections.filter((el) => el.CollectionId === id)[0];
          if (activeCollectionData === undefined) {
            history.push(`/b/collection/home`);
            return;
          }
          setActiveCollectionDetails(activeCollectionData);
        } else {
          const data = {
            CollectionName: '',
            CollectionDesc: '',
            isEditable: 0,
          };
          setActiveCollectionDetails(data);
        }
      } else {
        const data = {
          CollectionName: id,
          CollectionDesc: '',
          isEditable: 0,
        };

        if (
          id.toLowerCase() === 'uncategorized' ||
          id.toLowerCase() === 'trash'
        ) {
          data.isEditable = 1;
          data.editCollection = 0;
        }

        if (id.toLowerCase() === 'home') {
          data.CollectionName = 'Home';
          data.CollectionDesc = 'Recently Added Bookmarks';
        }

        if (id.toLowerCase() === 'uncategorized') {
          data.CollectionDesc =
            'To organize these bookmarks just drag and drop them into the desired Channel';
        }

        if (id.toLowerCase() === 'trash') {
          data.CollectionDesc =
            'To restore trashed items just drag them back into the collection.';
        }

        setActiveCollectionDetails(data);
      }
      getBookmarks(id);
    }
  }, [id]);

  useEffect(() => {
    if (defaultRoutes.includes(id.toLowerCase())) {
      return;
    }
    const activeCollection = userCollections.find(
      (el) => el.CollectionId === id,
    );
    setActiveCollectionDetails(activeCollection);
  }, [userCollections]);

  const getBookmarks = async (collectionId) => {
    setIsLoading(true);

    const url = generateAPIPath(collectionId);

    fetchAPI
      .get(url, {
        headers: {
          collectionid: collectionId,
        },
      })
      .then((data) => {
        const bookmarkSort = data.sort(function (a, b) {
          return new Date(b.DateCreated) - new Date(a.DateCreated);
        });

        const bookmarkImages = bookmarkSort
          .filter((b) => b.BookmarkType === 'image')
          .map((b) => {
            return {
              Id: b.Id,
              src: b.BookmarkImage,
            };
          });

        setImages(bookmarkImages);
        setCollectionBookmarks(bookmarkSort);
      })
      .finally(() => setIsLoading(false));
  };

  const sortOrder = async (order) => {
    let newOrder;
    const regex = /([a-zA-Z]+?)(s\b|\b)*:\/\/([www.]*)/g;

    switch (order) {
      case 'Title A-Z':
        newOrder = collectionBookmarks.sort(
          (a, b) =>
            a.BookmarkName &&
            a.BookmarkName.localeCompare(b.BookmarkName && b.BookmarkName),
        );
        break;

      case 'Title Z-A':
        newOrder = collectionBookmarks.sort(
          (a, b) =>
            b.BookmarkName &&
            b.BookmarkName.localeCompare(a.BookmarkName && a.BookmarkName),
        );
        break;

      case 'Oldest':
        newOrder = collectionBookmarks.sort(
          (a, b) => new Date(a.DateCreated) - new Date(b.DateCreated),
        );
        break;

      case 'Latest':
        newOrder = collectionBookmarks.sort(
          (a, b) => new Date(b.DateCreated) - new Date(a.DateCreated),
        );
        break;

      case 'Url A-Z':
        newOrder = collectionBookmarks.sort(
          (a, b) =>
            a.BookmarkURL.replace(regex, '') &&
            a.BookmarkURL.replace(regex, '').localeCompare(
              b.BookmarkURL.replace(regex, '') &&
                b.BookmarkURL.replace(regex, ''),
            ),
        );
        break;

      case 'Url Z-A':
        newOrder = collectionBookmarks.sort(
          (a, b) =>
            b.BookmarkURL.replace(regex, '') &&
            b.BookmarkURL.replace(regex, '').localeCompare(
              a.BookmarkURL.replace(regex, '') &&
                a.BookmarkURL.replace(regex, ''),
            ),
        );
        break;

      default:
        newOrder = collectionBookmarks.sort(
          (a, b) => new Date(b.DateCreated) - new Date(a.DateCreated),
        );
    }

    setCollectionBookmarks(newOrder);
    setSortTime(new Date());
  };

  const addToSelected = (bookmarkId) => {
    if (selectedBookmarks.includes(bookmarkId)) {
      const selectedBookmarkArr = selectedBookmarks.filter(
        (el) => el !== bookmarkId,
      );
      setSelectedBookmarks(selectedBookmarkArr);
      return;
    }

    const selectedBookmarkArr = [bookmarkId, ...selectedBookmarks];
    setSelectedBookmarks(selectedBookmarkArr);
  };

  const moveToTrash = async (bookmarkId) => {
    const accessToken = await getToken();

    fetch('/apiv2/removeBookmark', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        id: bookmarkId,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('error');
        }
        return response.json();
      })
      .then(() => {
        setCollectionBookmarks(
          collectionBookmarks.filter((el) => el.Id !== bookmarkId),
        );
      });
  };

  const openImageLightbox = (Id) => {
    const obj = images.findIndex((el) => el.Id === Id);

    setPhotoIndex(obj);
    setIsOpen(true);
  };

  const removeSharedCollection = () => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Are you sure you want to remove this shared collection?',
        text: 'You will need to request access from the owner if you want to follow again',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, get me outta here!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const accessToken = await getToken();

          fetch('/apiv2/removeSharedCollection', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
              collectionId: activeCollectionDetails?.CollectionId,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                console.log('error');
              }
              return response.json();
            })
            .then(() => {
              setUserCollections(
                userCollections.filter(
                  (el) =>
                    el.CollectionId !== activeCollectionDetails?.CollectionId,
                ),
              );
              history.push(`/b/collection/home`);
            });
        }
      });
  };

  if (isLoading) {
    return (
      <div className="d-flex loading-bookmarks" style={{ height: '75vh' }}>
        <img src={loadingGif} />
      </div>
    );
  }

  return (
    <>
      <h2 className="styled-header d-flex">
        <p className="mx-0 my-auto">
          {activeCollectionDetails?.CollectionName}
        </p>
        {activeCollectionDetails?.isOwn === 0 && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                Owner: {activeCollectionDetails?.OwnerEmail}
              </Tooltip>
            }
          >
            <Button
              variant="link"
              className="text-muted"
              onClick={() =>
                window.open(`mailto:${activeCollectionDetails?.OwnerEmail}`)
              }
            >
              <LockIcon />
            </Button>
          </OverlayTrigger>
        )}
        {activeCollectionDetails?.isEditable === 1 &&
          activeCollectionDetails?.editCollection !== 0 && (
            <OverlayTrigger
              placement={'top'}
              overlay={<Tooltip>Edit Collection</Tooltip>}
            >
              <Button
                variant="link"
                onClick={() => setShowCollectionCreator(true)}
              >
                <svg
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  stroke="grey"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                </svg>
              </Button>
            </OverlayTrigger>
          )}
        {activeCollectionDetails?.isOwn === 1 && (
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip>Share Collection</Tooltip>}
          >
            <Button
              variant="link"
              onClick={() => setShowCollectionSharer(true)}
            >
              <ShareIcon />
            </Button>
          </OverlayTrigger>
        )}
        <Dropdown as={ButtonGroup} alignRight>
          <Dropdown.Toggle variant="link">
            <span>
              <svg
                viewBox="0 0 24 24"
                width="18"
                height="18"
                stroke="grey"
                strokeWidth="1"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="21" y1="10" x2="7" y2="10"></line>
                <line x1="21" y1="6" x2="3" y2="6"></line>
                <line x1="21" y1="14" x2="3" y2="14"></line>
                <line x1="21" y1="18" x2="7" y2="18"></line>
              </svg>
              {/* {currentSort} */}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="1" onClick={() => sortOrder('Latest')}>
              Latest
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" onClick={() => sortOrder('Oldest')}>
              Oldest
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => sortOrder('Title A-Z')}>
              Title A-Z
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => sortOrder('Title Z-A')}>
              Title Z-A
            </Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={() => sortOrder('Url A-Z')}>
              Url A-Z
            </Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={() => sortOrder('Url Z-A')}>
              Url Z-A
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {activeCollectionDetails?.isOwn === 0 && (
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip>Remove Shared Collection</Tooltip>}
          >
            <Button
              variant="link"
              className="text-muted"
              onClick={() => removeSharedCollection()}
            >
              <XIcon />
            </Button>
          </OverlayTrigger>
        )}

        <div className="siimpl-sort ml-auto">
          {activeCollectionDetails?.CollectionName.toLowerCase() ===
            'trash' && (
            <EmptyTrash TrashEmptied={() => setCollectionBookmarks([])}>
              Empty Trash
            </EmptyTrash>
          )}
        </div>
      </h2>
      <p className="styled-title">{activeCollectionDetails?.CollectionDesc}</p>
      {collectionBookmarks?.length === 0 &&
        !isLoading &&
        collectionBookmarks?.length === 0 && (
          <EmptyBookmark activeCollection={activeCollection} />
        )}

      <BookmarkMasonry
        openImageLightbox={openImageLightbox}
        collectionBookmarks={collectionBookmarks}
        addToSelected={(Id) => addToSelected(Id)}
        moveToTrash={(Id) => moveToTrash(parseInt(Id))}
        activeCollectionDetails={activeCollectionDetails}
        selectedBookmarks={selectedBookmarks}
      />

      <Modal
        show={showCollectionCreator}
        onClose={() => setShowCollectionCreator(false)}
      >
        <CollectionEditor
          collection={activeCollectionDetails}
          isAdd={false}
          close={() => setShowCollectionCreator(false)}
        />
      </Modal>

      <Modal
        show={showCollectionSharer}
        onClose={() => setShowCollectionSharer(false)}
      >
        <CollectionSharer
          collection={activeCollectionDetails}
          onClose={() => setShowCollectionSharer(false)}
        />
      </Modal>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images?.length].src}
          prevSrc={
            images[(photoIndex + images?.length - 1) % images?.length].src
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex - 1) % images?.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images?.length)
          }
        />
      )}

      {selectedBookmarks.length > 0 && (
        <MultiSelect
          selectedBookmarks={selectedBookmarks}
          setSelectedBookmarks={(selectedBookmarkIds) =>
            setSelectedBookmarks(selectedBookmarkIds)
          }
          isEditable={activeCollectionDetails.isEditable}
          activeCollection={activeCollection}
        />
      )}
    </>
  );
}

import { Button } from 'react-bootstrap';
import React from "react"
import bgImg from './img/sl1.jpg'
import AppleStoreLogo from './img/appstore.png';
import GooglePlayLogo from './img/playstore.png';
import './Private.scss';

export default function Private() {
  return (
		<div id="private" style={{ backgroundImage: `url(${bgImg})` }}>
			<div className="container">
				<div className="beta-container my-auto mx-0">
					<a href="/" className="navbar-brand">
                        Siimpl
						<span style={{ color: "rgb(67, 160, 209)" }}>.</span>
					</a>
					<div className="mb-4">
						<Button variant="dark" onClick={() => window.open("https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621", "_blank")}>
							<img src={AppleStoreLogo} alt="" className="platform-image img-fluid" style={{ height: "4.5rem" }} />
						</Button>
						<Button variant="dark" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.siimpl", "_blank")}>
							<img src={GooglePlayLogo} alt="" className="platform-image img-fluid" style={{ height: "4.5rem" }} />
						</Button>
					</div>
					<div className="extensions">
						<p>Browser Extensions also available:</p>
						<ul className="platforms-list list-inline">
							<li className="list-inline-item"><a href="https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc" target="_blank" rel="noreferrer"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/240px-Google_Chrome_icon_%28September_2014%29.svg.png" alt="" className="platform-image img-fluid" /></a></li>
							<li className="list-inline-item"><a href="https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha" target="_blank" rel="noreferrer"><img src="https://download.logo.wine/logo/Microsoft_Edge/Microsoft_Edge-New-Logo.wine.png" alt="" className="platform-image img-fluid" style={{ height: "5rem" }} /></a></li>
							<li className="list-inline-item"><a href="https://addons.mozilla.org/en-CA/firefox/addon/siimpl-bookmarking/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search" target="_blank" rel="noreferrer"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Firefox_Project_Logo%2C_2019.svg/1024px-Firefox_Project_Logo%2C_2019.svg.png" alt="" className="platform-image img-fluid" /></a></li>
						</ul>
					</div>
					<Button variant="dark" className="sign-up-btn" onClick={() => window.open("/login")}>
                        Sign Up Free
					</Button>
					<h1>Start collecting and organizing what you like 😜.</h1>
				</div>
			</div>
		</div>
  )
}

import React, { useState, useEffect, useContext } from 'react';
import { getToken } from '../Auth/firebase';
import { DataContext } from '../Context/DataProvider';
import { Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../Assets/feather/check.svg';

import './Actions.scss';

export default function CollectionPin(props) {
  const [hasPinSet, setHasPinSet] = useState(false);
  const [pinAccepted, setPinAccepted] = useState(false);
  const [collectionPin, setCollectionPin] = useState('');
  const [pinMessage, setPinMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { setUserCollections } = useContext(DataContext);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      setHasPinSet(true);
    }
  }, []);

  const getAllCollections = async (ev) => {
    setCollectionPin('');
    setIsLoading(true);
    ev.preventDefault();

    const accessToken = await getToken();

    //TODO: create a fetchAPI helper
    fetch('/apiv2/getCollectionsInclude', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        collectionpin: collectionPin,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Not 2xx response', { cause: response });
        }
        return response.json();
      })
      .then((data) => {
        const collectionSort = data.sort(function (a, b) {
          return (
            new Date(b.DateShared || b.DateCreated) -
            new Date(a.DateShared || a.DateCreated)
          );
        });
        setUserCollections(collectionSort);
        setIsLoading(false);
        setPinAccepted(true);

        document.body.click();
        setTimeout(() => {
          console.log('close');
          props.close();
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setPinMessage("The pin doesn't match or you haven't set one up");
      })
      .finally(() => setIsLoading(false));
  };

  if (!hasPinSet) {
    return (
      <div id="collection_creator">
        <div className="creator_container">
          <h1 className="m-0">Collections Pin</h1>
          <p className="text-muted">
            Enter your pin to see any hidden collections you may have.
          </p>
          <div className="creator-input-container" style={{ width: 'unset' }}>
            <label>Collection Pin</label>
            <h1>
              <small style={{ fontWeight: '200' }}>
                No pin found in settings!
              </small>
            </h1>
          </div>
          <p className="m-0">{pinMessage}</p>
          <p>
            Set one up <Link onClick={() => props.openSettings()}>here</Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div id="collection-editor" className="pb-0">
      <div className="creator_container">
        <h6 className="m-0">Collections Pin</h6>
        <p className="text-muted" style={{ fontSize: '12px' }}>
          Enter your pin to see any hidden collections you may have.
        </p>
        <Form className="mb-3" onSubmit={(ev) => getAllCollections(ev)}>
          <div
            className="creator-input-container"
            style={{ width: 'unset', display: 'flex' }}
          >
            <input
              type="password"
              placeholder="Pin..."
              value={collectionPin}
              onChange={(e) => setCollectionPin(e.target.value)}
              autoFocus
              spellCheck={false}
              autoComplete="off"
            />
            <div style={{ margin: 'auto', transform: 'translateX(-30px)' }}>
              {isLoading && (
                <Spinner animation="border" variant="primary" size="sm" />
              )}
              {pinAccepted && (
                <div style={{ color: 'green' }}>
                  <CheckIcon />
                </div>
              )}
            </div>
          </div>
        </Form>
        {pinMessage && (
          <p
            className="m-0"
            style={{
              background: '#f5a2a2',
              padding: '10px',
              fontSize: '13px',
              color: '#372f2f',
              fontWeight: 'bold',
              borderRadius: '5px',
              border: 'solid 1px #a51b1b',
            }}
          >
            {pinMessage}
          </p>
        )}

        <p>
          <small>
            Haven&apos;t got a pin? set one up{' '}
            <Link onClick={() => props.openSettings()}>here</Link>
          </small>
        </p>
      </div>
    </div>
  );
}

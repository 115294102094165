import styled from '@emotion/styled'

export const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 10000;
  inset: 0;
`

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background: #3838386e;
  z-index: 1000;
`

export const ModalContainer = styled.div`
  background: white;
  z-index: 10000000;
  margin: auto;
  padding-right: 0px;
  border-radius: 10px;
  width: 95vw;
  max-height: 90vh;
  overflow: hidden;
`

import React, { useState } from 'react';
import { getToken } from '../Auth/firebase';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function EmptyTrash(props) {
  const [isEmptying, setIsEmptying] = useState(false);

  const EmptyTrash = async () => {
    setIsEmptying(true);
    const accessToken = await getToken();
    fetch('/apiv2/EmptyTrash', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log('error');
        }
        return response.json();
      })
      .then(() => {
        toast(`😃 Trash Emptied`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.TrashEmptied();
        setIsEmptying(false);
      });
  };

  return (
    <Button
      variant="outline-secondary empty-trash-btn"
      size="sm"
      onClick={() => EmptyTrash()}
    >
      {isEmptying ? 'Emptying Trash' : props.children}
    </Button>
  );
}

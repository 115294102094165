import React from "react";
import bg from './img/half_bg.jpg';

export default function Terms() {
  return (
		<div id="auth_screen" >
			<div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
			<div className="auth-container">
				<div className="logo">
					<a href="/" className="font-weight-bold navbar-brand" style={{ color: "rgb(0, 0, 0)", fontSize: "1.2rem", marginBottom: "30px" }}>
                        Siimpl
						<span style={{ color: "rgb(67, 160, 209)" }}>.</span>
						<span className="beta-word" style={{ color: "rgb(67, 160, 209)" }}>BETA</span>
					</a>
				</div>
				<div className="login-option-container my-5 py-5">
					<h2>Data Deletion</h2>
					<p>Siimpl Software would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
					<p>The right to access – You have the right to request Siimpl Software for copies of your personal data. We may charge you a small fee for this service.</p>
					<p>The right to rectification – You have the right to request that Siimpl Software correct any information you believe is inaccurate. You also have the right to request Siimpl Software to complete the information you believe is incomplete.</p>
					<p>The right to erasure – You have the right to request that Siimpl Software erase your personal data, under certain conditions.</p>
					<p>The right to restrict processing – You have the right to request that Siimpl Software restrict the processing of your personal data, under certain conditions.</p>
					<p>The right to object to processing – You have the right to object to Siimpl Software’s processing of your personal data, under certain conditions.</p>
					<p>The right to data portability – You have the right to request that Siimpl Software transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
					<p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:</p>
					<p>Email us at: <b>hello@siimpl.co</b></p>
					<p>Chat: <b>use the below chat icon</b></p>
					<p>Delete: <b>you can delete all data and accounts from the settings page</b></p>
				</div>
			</div>

		</div>
  )
}

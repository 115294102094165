import React, { useRef } from 'react';
import { BookmarkCard } from '../Components/BookmarkCard/BookmarkCard';
import Masonry from 'react-masonry-css';

import './BookmarkMasonry.scss';

const breakpointColumnsObj = {
  default: 5,
  1800: 5,
  1600: 4,
  700: 2,
  100: 1,
};

export default function SearchContainer(props) {
  const ref = useRef();

  return (
    <div className="search-result-container" ref={ref}>
      <h2 className="styled-header d-flex">
        <p className="mx-0 my-auto">Search Results</p>
      </h2>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid bookmark-grid"
        columnClassName="my-masonry-grid_column"
      >
        {props.searchResults
          .filter((el) => el.item.Collection !== 'tabs')
          .map((data, index) => (
            <BookmarkCard
              key={index}
              bookmark={data.item}
              isPreview={true}
              activeCollectionDetails={props.activeCollectionDetails}
              addToSelected={props.addToSelected}
              setSingleSelectedBookmark={props.setSingleSelectedBookmark}
              setSingleSelectedBookmarkCopy={
                props.setSingleSelectedBookmarkCopy
              }
              setShowBookmarkEditor={props.setShowBookmarkEditor}
              moveToTrash={props.moveToTrash}
              showReader={props.showReader}
              openImageLightbox={(e) => props.openImageLightbox(e)}
              selectedBookmarks={[]}
              {...props}
            />
          ))}
      </Masonry>

      <div className="text-center mt-5">
        <p>Not seeing what you want? try refining your search</p>
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DataContext } from '../../Context/DataProvider';
import { fetchAPI } from '../../Helpers/fetchAPI.helper';
import { BookmarkType } from '../../Types/BookmarkType';
import BookmarkMover from '../BookmarkMover/BookmarkMover.component';
import Modal from '../Modal/Modal.component';

interface MutliSelectProps {
  selectedBookmarks: string[];
  setSelectedBookmarks: (selectedBookmarkIds: string[]) => void;
  isEditable: number;
  activeCollection: any;
}

export const MultiSelect = (props: MutliSelectProps): JSX.Element => {
  const {
    selectedBookmarks,
    setSelectedBookmarks,
    isEditable,
    activeCollection,
  } = props;
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const { userCollections, collectionBookmarks, setCollectionBookmarks } =
    useContext(DataContext);

  const selectAllBookmarks = (): void => {
    if (selectedBookmarks?.length === collectionBookmarks.length) {
      setSelectedBookmarks([]);
      return;
    }

    setSelectedBookmarks(
      collectionBookmarks.map((bookmark: BookmarkType) => bookmark?.Id),
    );
  };

  const moveMultipleToTrash = (): void => {
    fetchAPI
      .post('/apiv2/removeMultipleBookmarks', {
        headers: {
          id: selectedBookmarks,
        },
      })
      .then(() => {
        setCollectionBookmarks((prevState: any[]) =>
          prevState.filter(
            (bookmark: { Id: string }) =>
              !(selectedBookmarks || []).includes(bookmark.Id),
          ),
        );
        setSelectedBookmarks([]);
      })
      .catch(console.error);
  };

  return (
    <>
      <div className="multi-select siimpl-sort m-auto">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => selectAllBookmarks()}
        >
          Select All
        </Button>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setShowMoveModal(true);
            setIsCopy(true);
          }}
        >
          Copy Selected
        </Button>
        {isEditable === 1 && (
          <>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setShowMoveModal(true);
                setIsCopy(false);
              }}
            >
              Move Selected
            </Button>
            <Button
              variant="outline-danger bg-danger text-white"
              size="sm"
              onClick={() => moveMultipleToTrash()}
            >
              Delete Selected
            </Button>
          </>
        )}
      </div>

      <Modal
        show={showMoveModal}
        onClose={() => {
          setShowMoveModal(false);
        }}
        maxWidth={570}
      >
        <BookmarkMover
          id={selectedBookmarks}
          isCopy={isCopy}
          userCollections={userCollections}
          activeCollection={activeCollection}
          onClose={() => {
            setShowMoveModal(false);
            setSelectedBookmarks([]);
          }}
        />
      </Modal>
    </>
  );
};
